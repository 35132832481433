<!-- CONSIDERATION:
Ada anomali disini.
Jika background putih untuk qrimage, maka image tidak bisa di scan.
Ini kita atasi dengan membuat background putih untuk satu card body.
 -->
<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="settings-profile"
                role="tabpanel"
                aria-labelledby="settings-profile-tab"
              >
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Two Factor Authentication</h5>

                    <div class="settings-profile">
                      <form
                        @submit.prevent="submitForm"
                        v-if="user.two_fa_is_active == false"
                      >
                        <div class="form-row mt-4">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="bg-white p-4">
                                <div v-html="qr_svg" class="mx-auto"></div>
                              </div>
                            </div>

                            <div class="row">
                              <input
                                style="color: #2a2e39"
                                type="text"
                                class="form-control my-3"
                                placeholder="Code"
                                v-model="google_secret"
                              />
                            </div>

                            <div class="row">
                              <input
                                type="submit"
                                value="Activate"
                                @click="submit"
                              />
                            </div>
                          </div>

                          <div class="col-md-6">
                            <p class="text-white">
                              Anda belum mengaktifkan Google Authenticator
                            </p>
                            <p class="text-white">
                              Google Authenticator adalah aplikasi yang berguna
                              untuk memperkuat keamanan akun Anda dari pencuri
                              atau peretas. Aplikasi ini akan menghasilkan PIN
                              yang berubah dari waktu ke waktu. Setelah
                              mengaktifkan Google Authenticator, Anda akan
                              diminta untuk memasukkan PIN yang berbeda setiap
                              kali Anda login dan menarik dana.
                            </p>
                          </div>
                        </div>
                      </form>

                      <div v-else>
                        <form @submit.prevent="submitFormDeactive">
                          <div class="form-row mt-4">
                            <div class="col-md-6">
                              <div class="row">Hapus 2FA</div>

                              <div class="row">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Code"
                                  v-model="google_secret"
                                />
                              </div>

                              <div class="row">
                                <input
                                  type="submit"
                                  value="Deactivate"
                                  @click="submit"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    Menu,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {},
      qr_svg: "",
      google_secret: "",
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    await this.getTwoFa();
  },

  methods: {
    async getTwoFa() {
      let self = this;
      // ambil data dia ketika login
      if (self.token != null && self.token != "") {
        var res = await thestore.getMyProfileData(self.token);
        self.user = res;

        var resqr = await thestore.getMyTwoFaQR(self.token);
        self.qr_svg = resqr.qr_svg;
      } else {
        self.$router.push("/");
      }
    },

    async submitForm() {
      var self = this;
      var res = await thestore.postTwoFaActivate(
        self.token,
        self.google_secret
      );
      // console.log("res", res);
      if (res.success == true) {
        toast.success("2FA has been activated");
        self.getTwoFa();
        self.google_secret = "";
      } else {
        toast.warning(res.msg);
      }
    },

    async submitFormDeactive() {
      var self = this;
      var res = await thestore.postTwoFaDeactivate(
        self.token,
        self.google_secret
      );
      // console.log("res", res);
      if (res.success == true) {
        toast.success("2FA has been deactivated");
        self.getTwoFa();
        self.google_secret = "";
      } else {
        toast.warning(res.msg);
      }
    },
  },
};
</script>

<style scoped>
h5.card-title {
  color: #e2e3e6 !important;
}
</style>
