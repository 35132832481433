<template>
  <div style="color: #fff; text-align: justify;">
    <!-- TODO: Kalau mau edit ini, pergi ke https://summernote.org -->

    <h1>TRADING RULES / PERATURAN TATA TERTIB ATAU TATA CARA PERDAGANGAN (PTT)</h1>
    <h2>I. DEFINISI</h2>
    <ol>
        <li>
            Badan Pengawas Perdagangan Berjangka Komoditi yang selanjutnya disebut Bappebti adalah lembaga pemerintah yang tugas pokoknya melakukan pembinaan, pengaturan, pengembangan, dan pengawasan Perdagangan Berjangka.
        </li>
        <li>
            Bursa Berjangka adalah badan usaha yang menyelenggarakan dan menyediakan sistem dan/atau sarana untuk kegiatan jual beli Komoditi berdasarkan Kontrak Berjangka, Kontrak Derivatif Syariah, dan/atau Kontrak Derivatif lainnya.
        </li>
        <li>
            Anggota Bursa Berjangka adalah Pihak yang mempunyai hak untuk menggunakan sistem dan/atau sarana Bursa Berjangka dan hak untuk melakukan transaksi Kontrak Berjangka, Kontrak Derivatif Syariah, dan/atau Kontrak Derivatif lainnya sesuai dengan peraturan dan tata tertib Bursa Berjangka.
        </li>
        <li>
            Lembaga Kliring Berjangka dan Penjaminan Berjangka yang selanjutnya disebut Lembaga Kliring Berjangka adalah badan usaha yang menyelenggarakan dan menyediakan sistem dan/atau sarana untuk pelaksanaan kliring dan penjaminan penyelesaian transaksi Perdagangan Berjangka.
        </li>
        <li>
            Anggota Lembaga Kliring dan Penjaminan Berjangka yang selanjutnya disebut Anggota Kliring Berjangka adalah Anggota Bursa Berjangka yang mendapat hak untuk menggunakan sistem dan/atau sarana Lembaga Kliring Berjangka dan mendapat hak dari Lembaga Kliring Berjangka untuk melakukan kliring dan mendapatkan penjaminan dalam rangka penyelesaian transaksi Kontrak Berjangka, Kontrak Derivatif Syariah, dan/atau Kontrak Derivatif lainnya.
        </li>
        <li>
            Pasar Fisik Aset Kripto (Crypto Asset) di Bursa Berjangka, yang selanjutnya disebut Pasar Fisik Aset Kripto adalah pasar fisik Aset Kripto yang diselenggarakan menggunakan sarana elektronik yang dimiliki oleh Pedagang Fisik Aset Kripto untuk transaksi jual atau beli Aset Kripto yang pengawasan pasarnya dilakukan oleh Bursa Berjangka.
        </li>
        <li>
            Aset Kripto (Crypto Asset) yang selanjutnya disebut Aset Kripto adalah Komoditi tidak berwujud yang berbentuk digital, menggunakan kriptografi, jaringan informasi teknologi, dan buku besar yang terdistribusi, untuk mengatur penciptaan unit baru, memverifikasi transaksi, dan mengamankan transaksi tanpa campur tangan pihak lain.
        </li>
        <li>
            Pedagang Fisik Aset Kripto adalah pihak yang telah memperoleh persetujuan dari Kepala Bappebti untuk melakukan kegiatan transaksi yang berkaitan dengan Aset Kripto baik atas nama diri sendiri dan/atau memfasilitasi Pelanggan Aset Kripto.
        </li>
        <li>
            Pelanggan Aset Kripto adalah pihak yang menggunakan jasa Pedagang Fisik Aset Kripto untuk membeli atau menjual Aset Kripto yang diperdagangkan di Pasar Fisik Aset Kripto.
        </li>
        <li>
            Pengelola Tempat Penyimpanan Aset Kripto adalah pihak yang telah memperoleh persetujuan dari Kepala Bappebti untuk mengelola tempat penyimpanan Aset Kripto dalam rangka melakukan penyimpanan, pemeliharaan, pengawasan dan/atau penyerahan Aset Kripto.
        </li>
        <li>
            Bukti Simpan dan Serah Aset Kripto adalah dokumen baik dalam bentuk hardcopy atau softcopy yang diterbitkan oleh Pengelola Tempat Penyimpanan sebagai tanda bukti kepemilikan atau atas penyerahan atas Aset Kripto yang disimpan.
        </li>
        <li>
            Wallet adalah media yang dipergunakan untuk menyimpan Aset Kripto baik berupa koin atau token.
        </li>
        <li>
            Koin adalah salah satu bentuk Aset Kripto yang memiliki konfigurasi blockchain tersendiri dan memiliki karakteristik seperti Aset Kripto yang muncul pertama kali yaitu bitcoin.
        </li>
        <li>
            Token adalah salah satu bentuk Aset Kripto yang dibuat sebagai produk turunan dari Koin.
        </li>
        <li>
            Website/Trading Platform mengacu pada situs online yang dikelola oleh PT KRIPTO INOVASI NUSANTARA, dengan tidak terbatas pada para pemilik, member, karyawan dan pihak-pihak yang terkait dengan PT KRIPTO INOVASI NUSANTARA. Tergantung pada konteks, “Website” dapat juga mengacu pada jasa, produk, situs, konten atau layanan lain yang disediakan oleh PT KRIPTO INOVASI NUSANTARA.
        </li>
        <li>
            Blockchain merupakan sebuah buku besar terdistribusi (distributed ledger) terbuka yang dapat mencatat transaksi antara dua pihak secara efisien dan dengan cara yang dapat diverifikasi secara permanen.
        </li>
        <li>
            Registrasi merupakan proses pendaftaran menjadi Member dalam platform PT KRIPTO INOVASI NUSANTARA yang merupakan proses verifikasi awal untuk memperoleh keterangan, pernyataan dalam penggunaan layanan platform.
        </li>
        <li>
            Member merupakan orang (perseorangan) yang telah melakukan registrasi pada PT KRIPTO INOVASI NUSANTARA, sehingga memperoleh otorisasi dari platform PT KRIPTO INOVASI NUSANTARA untuk melakukan kegiatan perdagangan Aset Kripto.
        </li>
        <li>
            Verifikasi merupakan proses pemeriksaan terhadap Member mengenai keterangan terkait Member dan informasi pribadi yang dicantumkan dalam proses Registrasi untuk divalidasi oleh PT KRIPTO INOVASI NUSANTARA guna mendapat layanan penuh platform.
        </li>
        <li>
            Verified Member merupakan Member yang telah melalui dan menyelesaikan tahapan Verifikasi yang dilakukan oleh PT KRIPTO INOVASI NUSANTARA.
        </li>
        <li>
            Layanan merupakan jasa yang disediakan oleh PT KRIPTO INOVASI NUSANTARA dalam memfasilitasi Verified Member untuk melakukan kegiatan membeli dan menjual Aset Kripto.
        </li>
        <li>
            Kegiatan Perdagangan Aset Kripto merupakan kegiatan transaksi jual-beli Aset Kripto atas dasar adanya pencapaian titik nilai kesepakatan para Verified Member dalam platform yang disediakan oleh PT KRIPTO INOVASI NUSANTARA.
        </li>
        <li>
            Akun Member merupakan akses yang diberikan kepada Member/Verified Member untuk mendapatkan informasi dan untuk melakukan kegiatan perdagangan Aset Kripto melalui platform.
        </li>
        <li>
            Kata Sandi merupakan kumpulan karakter yang terdiri dari rangkaian alfa-numerik atau kombinasi keduanya dan digunakan oleh Member/Verified Member untuk memverifikasi identitas dirinya kepada sistem keamanan pada platform PT KRIPTO INOVASI NUSANTARA.
        </li>
        <li>
            Nomor Telepon merupakan nomor telepon Member/Verified Member yang terdaftar sehingga memperoleh otorisasi untuk mendapatkan layanan. Perubahan nomor telepon dapat dilakukan dengan mengikuti peraturan pada laman bantuan yang tersedia di Website/Trading Platform.
        </li>
        <li>
            Konversi merupakan perubahan nilai mata uang fiat atau kartal ke dalam bentuk atau format Aset Kripto berdasarkan nilai tukar/rate yang berlaku dan sebaliknya.
        </li>
        <li>
            Pembeli merupakan Verified Member yang melakukan pembelian Aset Kripto melalui platform. Didalam mekanisme transaksinya pembeli dapat melakukan pembelian Aset Kripto yang didasarkan dengan nilai tukar/rate mata uang Rupiah.
        </li>
        <li>
            Penjual merupakan Verified Member yang melakukan penjualan Aset Kripto melalui platform. Didalam mekanisme transaksinya penjual dapat melakukan penjualan Aset Kripto yang didasarkan dengan nilai tukar/rate mata uang Rupiah.
        </li>
        <li>
            Deposit merupakan proses penyimpanan Rupiah/Aset Kripto oleh Member/Verified Member yang merujuk pada proses atau mekanisme penambahan (top up) yang dilakukan melalui Akun Member/Verified Member.
        </li>
        <li>
            Withdraw merupakan proses penarikan Rupiah/Aset Kripto oleh Verified Member yang merujuk pada proses atau mekanisme penarikan (withdrawal) yang dilakukan melalui Akun Verified Member.
        </li>
        <li>
            Harga Aset Kripto merupakan nilai tukar/rate Aset Kripto dalam platform PT KRIPTO INOVASI NUSANTARA yang bersifat fluktuatif, dengan nilai sesuai permintaan (supply) dan persediaan (demand) pada market.
        </li>
        <li>
            Alamat Aset Kripto merupakan alamat dompet Aset Kripto milik Member/Verified Member, diciptakan khusus untuk Member/Verified Member dan dapat digunakan berkali-kali sebagai dompet Aset Kripto yang disediakan di dalam platform. Dalam faktor teknisnya, alamat Aset Kripto memiliki peranan ataupun fungsi untuk menerima dan mengirim Aset Kripto.
        </li>
        <li>
            Limit Pengiriman Aset Kripto merupakan batas minimal dan maksimal dalam melakukan pengiriman Aset Kripto ke dompet Aset Kripto lain per-harinya. Perlu untuk dijelaskan bahwa setiap Akun Verified Member akan memiliki limit pengiriman yang berbeda dan disesuaikan dengan Syarat dan Ketentuan ini dengan Peraturan Perundang-Undangan yang berlaku.
        </li>
        <li>
            Limit Penarikan Rupiah merupakan batas minimal dan maksimal penarikan Rupiah per-harinya. Berkenaan dengan limit penarikan Rupiah. Perlu untuk dijelaskan bahwa setiap Akun Verified Member akan memiliki limit penarikan yang berbeda yang disesuaikan dengan Syarat dan Ketentuan dan Peraturan Perundang-Undangan yang berlaku.
        </li>
        <li>
            Rekening Bank merupakan rekening bank yang telah didaftarkan oleh Member dengan kewajiban kesamaan nama pemegang rekening dan nama Member.
        </li>
        <li>
            Order Book daftar antrian harga jual dan antrian harga beli yang tersedia pada aplikasi trading platform agar Verified Member dapat membeli atau menjual Aset Kripto menggunakan harga yang ditentukan oleh Verified Member. Order Book dibagi menjadi dua bagian, yaitu:
            <ul>
                <li>Market Beli – Daftar permintaan pembelian Aset Kripto lengkap dengan jumlah Aset Kripto dan harga yang ditawarkan.</li>
                <li>Market Jual – Daftar Aset Kripto yang dijual lengkap dengan jumlah Aset Kripto dan harga yang diminta.</li>
            </ul>
        </li>
        <li>
            Dompet Aset Kripto merupakan komponen perangkat lunak dan informasi untuk menyimpan dan menggunakan Aset Kripto.
        </li>
        <li>
            Rupiah merupakan mata uang Negara Kesatuan Republik Indonesia. Mata uang yang diperdagangkan terhadap Aset Kripto pada platform.
        </li>
        <li>
            KYC (Know Your Customer) Principles merupakan proses penilaian terhadap calon Member dan Member untuk mengetahui latar belakang dan itikad baik terhadap perbuatan yang akan dilakukan dalam sebuah kegiatan perdagangan Aset Kripto.
        </li>
        <li>
            AML (Anti Money Laundering) merupakan kegiatan untuk mengantisipasi dan menghentikan praktik pencucian uang.
        </li>
        <li>
            Undang-Undang Nomor 27 Tahun 2022 tentang Perlindungan Data Pribadi selanjutnya disebut UU PDP adalah bentuk instrumen hukum berkenaan untuk memberikan perlindungan terhadap kerahasiaan data personal atau perseorangan.
        </li>
        <li>
            Sistem Keamanan 2 (dua) Langkah (Two Step Verification) merupakan layanan yang diberikan platform PT KRIPTO INOVASI NUSANTARA sebagai opsi bagi Verified Member dalam memberikan keamanan tambahan atas Akun Verified Member.
        </li>
    </ol>

    <h2>II. PROSES PENDAFTARAN PELANGGAN ASET KRIPTO/MEMBER</h2>
    <ol>
        <li>
            Syarat menjadi Pelanggan Aset Kripto/Member adalah:
            <ul>
                <li>Pelanggan Aset Kripto/Member dapat melakukan proses registrasi atau pendaftaran Member melalui platform PT KRIPTO INOVASI NUSANTARA;</li>
                <li>Setuju dan sepakat untuk tunduk pada Syarat dan Ketentuan Umum ini;</li>
                <li>Berusia minimal 18 (delapan belas) tahun, atau telah menikah; dan</li>
                <li>Memiliki identitas yang sah secara hukum.</li>
                <li>Bukan merupakan warga Negara yang berasal dari negara Amerika Serikat, Myanmar, Cote D'Ivoire, Cuba, Iran, Republik Arab Syria, Belarus, Congo, Democratic Republic of Congo, Iraq, Liberia, Sudan, Zimbabwe, dan Korea Utara.</li>
            </ul>
        </li>
        <li>
            Atas setiap proses registrasi, calon Member wajib menunjukkan semua dan setiap data sesuai dengan identitas diri berupa:
        </li>
        <li>
            Registrasi Calon Member Warga Negara Indonesia:
            <ul>
                <li>Nama (sesuai dengan Identitas diri yang dilampirkan);</li>
                <li>Alamat rumah sesuai identitas;</li>
                <li>Alamat tinggal saat ini;</li>
                <li>Nomor telepon atau Handphone(nomor harus aktif dan digunakan secara pribadi);</li>
                <li>Tempat dan tanggal lahir (sesuai dengan identitas diri yang dilampirkan);</li>
                <li>Kewarganegaraan;</li>
                <li>Jenis kelamin;</li>
                <li>Foto kartu identitas yang masih berlaku. Kartu identitas yang dapat digunakan adalah: Kartu Tanda Penduduk (KTP) & Paspor.</li>
                <li>Pekerjaan;</li>
                <li>E-mail (alamat surat elektronik) yang aktif;</li>
            </ul>
        </li>
        <li>
            Registrasi Calon Member Warga Negara Asing:
            <ul>
                <li>Nama (sesuai dengan Identitas diri yang dilampirkan);</li>
                <li>Alamat rumah sesuai identitas;</li>
                <li>Alamat tinggal saat ini;</li>
                <li>Nomor telepon atau Handphone(nomor harus aktif dan digunakan secara pribadi);</li>
                <li>Tempat dan tanggal lahir (sesuai dengan identitas diri yang dilampirkan);</li>
                <li>Kewarganegaraan;</li>
                <li>Jenis kelamin;</li>
                <li>Foto kartu identitas yang masih berlaku. Paspor, Kartu Ijin Tinggal Terbatas (KITAS), dan Kartu Ijin Tinggal Terbatas (KITAP);</li>
                <li>Pekerjaan;</li>
                <li>E-mail (alamat surat elektronik) yang aktif;</li>
            </ul>
        </li>
        <li>
            Dan/atau segala sesuatu yang diminta dan diperlukan berkenaan dengan syarat registrasi yang ditentukan, dan calon Member dengan ini menyatakan serta menjamin bahwa segala data/keterangan/dokumen/informasi/pernyataan apapun yang diberikan berkenaan dengan proses registrasi sebagai Member PT KRIPTO INOVASI NUSANTARA adalah lengkap, asli, benar dan sesuai dengan keadaan yang sebenarnya serta merupakan data/keterangan/dokumen/informasi/pernyataan terkini yang tidak/belum dilakukan perubahan dan masih berlaku/tidak daluarsa serta tidak/belum ada perubahan atau kondisi lainnya yang disetujui berdasarkan kebijakan dalam halaman registrasi pada Website /Trading Platform.
        </li>
        <li>
            Member/Member dengan ini setuju bahwa proses menjadi Member PT KRIPTO INOVASI NUSANTARA hanya akan berlaku efektif setelah seluruh persyaratan PT KRIPTO INOVASI NUSANTARA dipenuhi oleh Member dan proses registrasi telah melalui proses verifikasi untuk disetujui PT KRIPTO INOVASI NUSANTARA. Segala risiko yang timbul sehubungan dengan penutupan/pemblokiran/pembekuan Akun yang diakibatkan oleh kesalahan dan/atau kelalaian Verified Member, akan menjadi tanggung jawab Verified Member dan PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi kepada Verified Member atau Pihak manapun dalam bentuk apapun atas segala tuntutan/klaim dan ganti rugi dari Verified Member atau Pihak manapun sehubungan dengan penutupan Akun Verified Member/Member.
        </li>
        <li>
            Seluruh data, keterangan, informasi, pernyataan, dokumen yang diperoleh PT KRIPTO INOVASI NUSANTARA berkenaan dengan Verified Member, akan menjadi milik PT KRIPTO INOVASI NUSANTARA dan PT KRIPTO INOVASI NUSANTARA berhak untuk melakukan verifikasi, mencocokan, menilai, merahasiakan atau menggunakannya untuk kepentingan PT KRIPTO INOVASI NUSANTARA sesuai dengan ketentuan hukum yang berlaku tanpa adanya kewajiban PT KRIPTO INOVASI NUSANTARA untuk Memberitahu atau meminta persetujuan, Memberikan jaminan atau ganti rugi dan dengan alasan apapun kepada Verified Member.
        </li>
        <li>
            PT KRIPTO INOVASI NUSANTARA akan mengatur, mengelola dan melakukan pengawasan menurut tata cara/prosedur yang ditetapkan PT KRIPTO INOVASI NUSANTARA atas segala data, keterangan, informasi, pernyataan, dokumen atau segala sesuatu yang berkenaan dengan Verified Member maupun kegiatan usaha atau transaksi Member/Verified Member yang terkait dengan Akun Member/Verified Member.
        </li>
        <li>
            Member/Member/Verified Member dengan ini Memberikan persetujuan dan kuasa kepada PT KRIPTO INOVASI NUSANTARA untuk menggunakan semua data, keterangan dan informasi yang diperoleh PT KRIPTO INOVASI NUSANTARA mengenai Member/Verified Member termasuk namun tidak terbatas pada penggunaan sarana komunikasi pribadi Member/Member/Verified Member untuk segala keperluan lainnya sepanjang dimungkinkan dan diperkenankan oleh Perundang – Undangan yang berlaku, termasuk yang bertujuan untuk pemasaran bagi PT KRIPTO INOVASI NUSANTARA ataupun bagi pihak lain yang bekerjasama dengan PT KRIPTO INOVASI NUSANTARA. Untuk penggunaan data yang memerlukan persetujuan pihak lain, dengan ini Member/Verified Member menyatakan bahwa telah Memberikan persetujuan tertulis kepada Pihak Ketiga manapun untuk penggunaan data, keterangan dan informasi tersebut, dan oleh karena itu PT KRIPTO INOVASI NUSANTARA dengan ini tidak akan Memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun kepada Member/Verified Member dan pihak manapun atas segala risiko, tuntutan, gugatan dan/atau ganti rugi yang mungkin timbul dikemudian hari sehubungan dengan penggunaan data, keterangan dan informasi yang telah memperoleh persetujuan tertulis tersebut oleh PT KRIPTO INOVASI NUSANTARA.
        </li>
        <li>
            Identifikasi dan nama akun Member/verified Member sebagai berikut:
            <ul>
                <li>Setiap Akun Member yang dibuka akan diadministrasikan oleh PT KRIPTO INOVASI NUSANTARA berdasarkan identifikasi khusus menurut nama Member/Verified Member yang akan berlaku juga sebagai nama/identitas Akun sesuai dengan yang tertera pada identitas Member/Member/Verified Member. Member/Member/Verified Member dilarang untuk menggunakan Akun Member/Verified Member selain Akun milik Member/Member/Verified Member, atau mengakses Akun Member/Verified Member lain, atau membantu orang lain untuk mendapatkan akses tanpa izin ke dalam Akun tersebut. Seluruh penggunaan Akun Member/Member/Verified Member adalah tanggung jawab sepenuhnya dari pemilik Akun yang terdata di dalam sistem Website/Trading Platform.</li>
                <li>Member/Verified Member wajib menggunakan dan mencantumkan email Member/Member/Verified Member dan kata sandi yang telah diverifikasi PT KRIPTO INOVASI NUSANTARA pada saat registrasi. Sistem PT KRIPTO INOVASI NUSANTARA akan menolak secara otomatis proses layanan atas Akun, bilamana tidak disertai dengan email dan kata sandi yang benar. Member/Member/Verified Member bertanggung jawab untuk menjaga kerahasiaan kata sandi, Akun, PIN, akses wallet Aset Kripto , akses login email dan segala jenis aktivitas yang meliputi transaksi di dalam Akun Member/Member/Verified Member. Member/Verified Member bertanggung jawab penuh atas penggunaan kata sandi dan Akun pada PT KRIPTO INOVASI NUSANTARA. Apabila terjadi penggunaan sandi dan/atau Akun tanpa seizin Member/Verified Member dan terjadi dugaan pelanggaran lain, Member/Verified Member wajib menghubungi PT KRIPTO INOVASI NUSANTARA dengan mengirimkan e-mail ke info@coinx.co.id disertai informasi pendukung. PT KRIPTO INOVASI NUSANTARA tidak bertanggung jawab atas kerugian yang ditimbulkan atas adanya penyalahgunaan Akun dan/atau kata sandi, dengan atau tanpa sepengetahuan Member/Verified Member.</li>
                <li>Member/Verified Member setuju tidak akan menggunakan jasa yang disediakan oleh Website/Trading Platform untuk melakukan tindakan kriminal dalam bentuk apapun, termasuk namun tidak terbatas pada, pencucian uang, perjudian, pembelian barang ilegal, kegiatan teroris atau kegiatan hacking. Setiap Member/Verified Member yang melanggar peraturan tersebut dapat diberhentikan dan harus bertanggungjawab dengan kerugian yang diderita oleh PT KRIPTO INOVASI NUSANTARA atau para pengguna lain di dalam Website/Trading Platform. PT KRIPTO INOVASI NUSANTARA berhak menutup Akun dan membekukan dana, Aset Kripto dan transaksi di dalamnya apabila menemukan aktifitas yang mencurigakan di dalam Akun tersebut hingga waktu yang tidak ditentukan.</li>
            </ul>
        </li>
    </ol>

    <h2>III. PERNYATAAN DAN JAMINAN</h2>
    <p>Adapun pernyataan dan jaminan dalam Peraturan Tata Tertib atau Tata Cara Perdagangan sebagai berikut:</p>
    <ol>
        <li>Semua layanan dalam Website/Trading Platform tidak Memberikan jaminan ataupun garansi dalam hal keuntungan/profit dalam perdagangan Aset Kripto dan PT KRIPTO INOVASI NUSANTARA tidak menjamin bahwa Website/Trading Platform akan selalu dapat diakses setiap waktu.</li>
        <li>Member/Verified Member menyatakan dan menjamin akan menggunakan layanan PT KRIPTO INOVASI NUSANTARA dengan baik dan penuh tanggung jawab serta tidak melakukan tindakan yang berlawanan dengan hukum, undang-undang serta peraturan yang berlaku di wilayah Republik Indonesia.</li>
        <li>Member/Verified Member menyatakan dan menjamin tidak akan menggunakan layanan PT KRIPTO INOVASI NUSANTARA dalam penjualan barang dan/jasa yang berhubungan dengan:
            <ul>
                <li>Narkotika, bahan dan/atau zat kimia untuk penelitian)</li>
                <li>Uang dan apa pun yang sejenis dengan uang, termasuk derivative)</li>
                <li>Barang dan/atau jasa yang melanggar Hak Kekayaan Intelektual)</li>
                <li>Amunisi, senjata api, bahan peledak, senjata tajam sesuai dengan Ketentuan Hukum yang berlaku)</li>
                <li>Barang dan/atau jasa yang menunjukkan informasi pribadi dari Pihak Ketiga yang melanggar hukum</li>
                <li>Dukungan terhadap skema Ponzi dan program</li>
                <li>Barang dan/atau jasa yang berhubungan dengan pembelian lotre, lay-away</li>
                <li>Jasa yang terkait dengan perbankan yang berada di luar wilayah Republik</li>
                <li>Pembayaran kartu</li>
                <li>Penyelesaian kredit dan/atau</li>
                <li>Dukungan terhadap organisasi terlarang atau dilarang oleh pemerintah.</li>
            </ul>
        </li>
        <li>Member/Verified Member menyatakan dan menjamin untuk tidak menggunakan layanan terhadap perbuatan yang terlibat dalam praktik perjudian dan/atau kegiatan lain yang mengenakan biaya masuk dan menjanjikan hadiah, termasuk namun tidak terbatas pada permainan kasino, perjudian dalam olahraga, usaha yang memfasilitasi perjudian dan cara undian.</li>
        <li>Member/Verified Member menyatakan dan menjamin bahwa seluruh data, informasi dan dokumen yang diberikan Member/Verified Member kepada PT KRIPTO INOVASI NUSANTARA merupakan data, informasi dan dokumen yang sebenar-benarnya, sah, jujur, transparan, lengkap dan akurat. Member/Verified Member menyatakan bersedia dituntut secara pidana maupun apabila PT KRIPTO INOVASI NUSANTARA mengetahui atau memperoleh informasi dari pihak manapun bahwa data, informasi dan dokumen yang diberikan Member/Verified Member ternyata tidak benar/tidak sepenuhnya benar/palsu. Member bersedia untuk melakukan pembaharuan data/informasi (profile update) apabila sewaktu-waktu diminta oleh PT KRIPTO INOVASI NUSANTARA dan selanjutnya seluruh dokumen yang sudah diberikan menjadi sepenuhnya milik PT KRIPTO INOVASI NUSANTARA.</li>
        <li>PT KRIPTO INOVASI NUSANTARA telah Memberikan keterangan dan penjelasan yang cukup mengenai layanan PT KRIPTO INOVASI NUSANTARA yang akan dipergunakan Member/Verified Member sesuai dengan ketentuan pada PTT dan Member/Verified Member telah mengerti dan memahami serta bersedia menanggung segala konsekuensi yang mungkin timbul sehubungan dengan penggunaan layanan PT KRIPTO INOVASI NUSANTARA termasuk manfaat, resiko dan biaya biaya yang melekat layanan dan layanan.</li>
        <li>Member/Verified Member dengan ini menyetujui dan Memberi kuasa kepada PT KRIPTO INOVASI NUSANTARA untuk menggunakan semua data, keterangan dan informasi yang diperoleh PT KRIPTO INOVASI NUSANTARA mengenai Member/Verified Member termasuk namun tidak terbatas pada penggunaan sarana komunikasi pribadi Member/Verified Member untuk segala keperluan lainnya sepanjang dimungkinkan dan diperkenankan oleh perundang-undangan yang berlaku, termasuk yang bertujuan untuk pemasaran produk-produk PT KRIPTO INOVASI NUSANTARA ataupun pihak lain, yang bekerjasama dengan PT KRIPTO INOVASI NUSANTARA. Untuk penggunaan data yang memerlukan persetujuan pihak lain, dengan ini Member/Verified Member menyatakan bahwa telah memperoleh persetujuan tertulis dari pihak ketiga manapun untuk penggunaan data, keterangan dan informasi tersebut, dan karena itu Member/Verified Member menjamin dan menyetujui bahwa PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun kepada Member/Verified Member atau pihak manapun atas segala risiko, kerugian, tuntutan dan/atau tanggung jawab yang mungkin timbul dikemudian hari sehubungan dengan penggunaan data, keterangan dan informasi yang telah memperoleh persetujuan tertulis tersebut oleh PT KRIPTO INOVASI NUSANTARA.</li>
        <li>Member/Verified Member menyatakan dan menjamin bahwa transaksi yang dilakukan dengan menggunakan layanan PT KRIPTO INOVASI NUSANTARA adalah transaksi yang tidak melanggar ketentuan peraturan perundang-undangan yang berlaku dan ketentuan mengenai penerimaan pelaksanaan transaksi sebagaimana diatur dalam PTT. Dalam hal PT KRIPTO INOVASI NUSANTARA menemukan indikasi pelaksanaan transaksi yang tidak sesuai dengan ketentuan peraturan perundang-undangan yang berlaku dan atau ketentuan mengenai penerimaan pelaksanaan transaksi yang diatur dalam PTT, maka PT KRIPTO INOVASI NUSANTARA mempunyai hak penuh untuk menutup Akun Member/Verified Member dengan menonaktifkan layanan PT KRIPTO INOVASI NUSANTARA pada Member/Verified Member, dan Member/Verified Member dengan ini setuju bahwa PT KRIPTO INOVASI NUSANTARA dengan ini tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Member/Verified Member, atau pihak manapun atas segala klaim dan atau tuntutan yang timbul sehubungan dengan penonaktifan layanan PT KRIPTO INOVASI NUSANTARA pada Member/Verified Member. Apabila terdapat dana milik Member/Verified Member yang dinonakfitkan sebagaimana yang dimakusd pada poin 8 diatas akan disimpan sementara oleh PT KRIPTO INOVASI NUSANTARA untuk selanjutnya dilaporkan kepada Bappebti, setelah mendapatkan jawaban (disposisi) dari Bappebt, PT KRIPTO INOVASI NUSANTARA akan melakukan tindaklanjut sebagai berikut:
            <ul>
                <li>Apabila ditemukan indikasi transaksi yang melanggar ketentuan, peraturan maupun perundang-undangan, PT KRIPTO INOVASI NUSANTARA akan mengikuti prosedur yag ditentukan oleh Bappebti atas dana yang dimiliki oleh Member/Verified Member yang melanggar serta memberitahukan hal tersebut melalui nomor kontak/email yang didaftarkan.</li>
                <li>Apabila tidak ditemukan indikasi transaksi yang melanggar ketentuan, peraturan maupun perundang-undangan, PT KRIPTO INOVASI NUSANTARA akan mengembalikan kepada Member/Verified Member.</li>
            </ul>
        </li>
        <li>Member/Verified Member menyatakan dan menjamin bahwa resiko terhadap penggunaan layanan, produk dan promosi Pihak Ketiga dengan Member/Verified Member (apabila ada), ditanggung oleh Member/Verified Member, dan Member/Verified Member menyatakan bahwa PT KRIPTO INOVASI NUSANTARA tidak bertanggung jawab atas layanan dan kinerja layanan Pihak Ketiga.</li>
        <li>Member/Verified Member dengan ini bertanggung jawab sepenuhnya dan setuju bahwa PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apa pun kepada Member/Verified Member atau pihak manapun atas segala kerugian dan atau klaim dan atau tuntutan yang timbul atau mungkin dialami oleh PT KRIPTO INOVASI NUSANTARA sebagai akibat dari kelalaian atau kegagalan Member/Verified Member dalam menjalankan transaksi.</li>
        <li>Member/Verified Member dengan ini Memberikan jaminan kepada PT KRIPTO INOVASI NUSANTARA bahwa Member/Verified Member beserta dengan seluruh karyawannya dan atau pihak lain yang bekerja sama dengan Member/Verified Member tidak akan memperbanyak dan atau membuat, Memberikan, menyewakan, menjual, memindahkan, mengalihkan, dan atau mengalih-fungsikan layanan PT KRIPTO INOVASI NUSANTARA baik sebagian atau seluruhnya kepada pihak lain dengan alasan apapun, termasuk namun tidak terbatas pada penyalahgunaan layanan Website/Trading Platform PT KRIPTO INOVASI NUSANTARA untuk melakukan transaksi selain dari yang telah ditentukan dalam PTT dengan maksud apa pun, termasuk namun tidak terbatas untuk kejahatan/penipuan/kecurangan. Apabila Member/Verified Member melanggar ketentuan tersebut, maka Member/Verified Member wajib bertanggung jawab atas segala kerugian, tuntutan dan atau gugatan yang timbul akibat dari pelanggaran tersebut dan dengan ini setuju bahwa PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Member/Verified Member atau pihak manapun atas segala klaim dan atau tuntutan dan atau gugatan yang timbul akibat pelanggaran tersebut.</li>
        <li>PT KRIPTO INOVASI NUSANTARA terus selalu berkomitmen dan menjamin data personal Member/Verified Member kami dan akan menindak tegas apabila ada pihak lain telah menggunakan data informasi Member/Verified Member kami;</li>
        <li>PT KRIPTO INOVASI NUSANTARA menjamin transfer lintas batas Aset Kripto;</li>
        <li>PT KRIPTO INOVASI NUSANTARA selalu menerapkan protokoler dan mekanisme berupa permintaan ijin dan persetujuan atau notifikasi kepada Member/Verified Member untuk segala bentuk pemrosesan data di marketplace didalam melakukan deposit, penarikan dan/atau pembaharuan data.</li>
        <li>Penolakan jaminan dan batasan tanggung jawab dalam peraturan tata tertib dan tata cara perdagangan:
            <ul>
                <li>PT KRIPTO INOVASI NUSANTARA selalu berusaha untuk menjaga Layanan yang aman, nyaman, dan berfungsi dengan baik, tetapi kami tidak dapat menjamin pengoperasian yang berkelanjutan atau bahwa akses ke Layanan kami selalu sempurna. Ada kemungkinan informasi dan data di situs PT KRIPTO INOVASI NUSANTARA tidak tersedia secara real time.</li>
                <li>PT KRIPTO INOVASI NUSANTARA tidak menjamin bahwa hasil yang diperoleh dari penggunaan layanan akan akurat atau dapat diandalkan.</li>
                <li>Member / Verified Member setuju bahwa Member / Verified Member menggunakan Layanan PT KRIPTO INOVASI NUSANTARA atas risiko Member / Verified Member sendiri, dan bahwa Layanan PT KRIPTO INOVASI NUSANTARA disediakan untuk Member / Verified Member atas dasar “SEBAGAIMANA ADANYA” dan “SEBAGAIMANA TERSEDIA”.</li>
                <li>Sejauh diizinkan oleh hukum yang berlaku, PT KRIPTO INOVASI NUSANTARA (termasuk Perusahaan Induk, direktur, dan karyawan) tidak bertanggung jawab, dan Member / Verified Member setuju untuk tidak meminta pertanggungjawaban PT KRIPTO INOVASI NUSANTARA, atas kerusakan atau kerugian (termasuk tetapi tidak terbatas pada kehilangan uang, reputasi, keuntungan, atau kerugian tidak berwujud lainnya) yang secara langsung atau tidak langsung disebabkan oleh hal-hal berikut:
                    <ul>
                        <li>Ketidakmampuan Member / Verified Member dalam menggunakan layanan PT KRIPTO INOVASI NUSANTARA termasuk dalam risiko pribadi.</li>
                        <li>Hilangnya Penggunaan, Hilangnya Keuntungan, Hilangnya Pendapatan, Hilangnya Data, Hilangnya Niat Baik, atau Kegagalan untuk merealisasikan simpangan yang diharapkan, untuk kasus apa pun secara langsung atau tidak langsung.</li>
                        <li>Setiap kerugian tidak langsung, insidental, khusus, atau konsekuensial, yang timbul dari atau sehubungan dengan penggunaan atau ketidakmampuan untuk menggunakan situs web atau layanan PT KRIPTO INOVASI NUSANTARA termasuk, tetapi tidak terbatas pada, kerugian yang disebabkan olehnya, bahkan jika PT KRIPTO INOVASI NUSANTARA telah diberitahu tentang kemungkinan tersebut kerugian.</li>
                        <li>Segala kerugian yang disebabkan oleh kelalaian Member / Verified Member termasuk namun tidak terbatas pada kelalaian login melalui perangkat pihak ketiga dan / atau kegagalan untuk menjaga kerahasiaan perangkat yang digunakan untuk login.</li>
                        <li>Kondisi dan kualitas produk atau aset crypto yang dapat diperdagangkan.</li>
                        <li>Pelanggaran Hak Kekayaan Intelektual.</li>
                        <li>Perselisihan antara Member / Verified Member Terverifikasi.</li>
                        <li>Pencemaran nama baik pihak lain.</li>
                        <li>Setiap penyalahgunaan Aset yang dibeli oleh Member / Verified Member.</li>
                        <li>Kerugian akibat pembayaran tidak resmi kepada pihak lain selain Rekening Resmi PT KRIPTO INOVASI NUSANTARA yang dengan cara apapun menggunakan nama PT KRIPTO INOVASI NUSANTARA atau kelalaian dalam penulisan rekening dan / atau informasi lain dan / atau kelalaian pihak bank.</li>
                        <li>Kesesuaian untuk tujuan tertentu, daya tahan, kepemilikan, dan non-pelanggaran.</li>
                        <li>Virus atau malware lainnya (bot, script, alat otomatisasi) yang diperoleh dengan mengakses atau menghubungkan ke layanan PT KRIPTO INOVASI NUSANTARA.</li>
                        <li>Proses skimming atau hacking, yang menyebabkan kerugian Member / Verified Member di Layanan PT KRIPTO INOVASI NUSANTARA.</li>
                        <li>Gangguan, bug, kesalahan, atau ketidakakuratan dalam Layanan PT KRIPTO INOVASI NUSANTARA.</li>
                        <li>Kerusakan perangkat keras Anda dari penggunaan Layanan PT KRIPTO INOVASI NUSANTARA apa pun.</li>
                        <li>Tindakan penegakan yang diambil sehubungan dengan akun Member / Verified Member.</li>
                        <li>Setiap peretasan yang dilakukan oleh pihak ketiga pada akun Member / Verified Member.</li>
                    </ul>
                </li>
                <li>Member / Verified Member mengakui dan menyetujui bahwa satu-satunya hak Member / Verified Member sehubungan dengan masalah atau ketidakpuasan layanan adalah berhenti menggunakan layanan.</li>
            </ul>
        </li>
    </ol>

    <h2>IV. KEWAJIBAN DAN TANGGUNG JAWAB</h2>
    <ol>
        <li>
            Member/Verified Member setuju untuk menanggung setiap risiko, kerugian atau akibat yang diderita Member/Verified Member yang disebabkan oleh, antara lain:
            <ul>
                <li>kerusakan, keterlambatan, kehilangan atau kesalahan pengiriman perintah dan komunikasi, secara elektronik yang disebabkan oleh Member/Verified Member;</li>
                <li>Laporan Akun PT KRIPTO INOVASI NUSANTARA atau pemberitahuan penggunaan layanan PT KRIPTO INOVASI NUSANTARA yang dikirim kepada Member/Verified Member diterima atau dibaca atau disalahgunakan oleh pihak yang tidak berwenang atas Akun PT KRIPTO INOVASI NUSANTARA;</li>
                <li>Password Akun diketahui oleh orang/pihak lain atas kesalahan Member/Verified Member;</li>
                <li>Member/Verified Member memahami dan setuju bahwa Member/Verified Member akan menggunakan Akun dan layanan PT KRIPTO INOVASI NUSANTARA untuk transaksi yang tidak bertentangan dengan ketentuan peraturan perundang – undangan dan/atau kebijakan internal PT KRIPTO INOVASI NUSANTARA yang berlaku dan/atau peraturan – peraturan lainnya yang berlaku secara nasional maupun internasional yang terkait dengan pelaksanaan transaksi tersebut baik secara langsung maupun tidak langsung, dan PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk apa pun kepada Member/Verified Member atau pihak mana pun atas segala klaim dan/atau tuntutan dan/atau kerugian yang timbul sehubungan dengan penggunaan layanan PT KRIPTO INOVASI NUSANTARA oleh Member/Verified Member untuk transaksi yang dikategorikan sebagai transaksi yang mencurigakan dan/atau transaksi yang dilarang oleh ketentuan peraturan perundang – undangan dan/atau kebijakan internal PT KRIPTO INOVASI NUSANTARA yang berlaku dan/atau peraturan – peraturan lainnya yang berlaku baik secara nasional maupun secara internasional yang terkait dengan kegiatan transaksi yang dilakukan oleh Member/Verified Member secara langsung maupun tidak langsung.</li>
                <li>Dalam melakukan transaksi menggunakan layanan PT KRIPTO INOVASI NUSANTARA, Member/Verified Member mengerti dan menyetujui bahwa terdapat sanksi – sanksi tertentu dikenakan oleh pemerintah, termasuk pemerintah Negara lain, dan/atau instansi berwenang lainnya terhadap beberapa negara, badan dan perorangan. Mengacu pada hal tersebut, PT KRIPTO INOVASI NUSANTARA berhak untuk tidak melaksanakan/memproses transaksi yang merupakan pelanggaran terhadap ketentuan sanksi tersebut, dan instansi berwenang dapat mensyaratkan pengungkapan informasi terkait. PT KRIPTO INOVASI NUSANTARA tidak bertanggung jawab apabila Website/Trading Platform PT KRIPTO INOVASI NUSANTARA atau pihak lain gagal atau menunda pelaksanaan transaksi, atau pengungkapan informasi sebagai akibat pelanggaran langsung maupun tidak langsung atas ketentuan sanksi tersebut.</li>
            </ul>
        </li>
        <li>
            Risiko
            <ul>
                <li>Perdagangan Aset Kripto merupakan aktivitas berisiko tinggi. Harga Aset Kripto fluktuatif, di mana harga dapat berubah secara signifikan dari waktu ke waktu. Sehubungan dengan fluktuasi harga, nilai Aset Kripto dapat bertambah maupun berkurang secara signifikan sewaktu-waktu. Semua Aset Kripto atau tidak, berpotensi untuk mengalami perubahan nilai secara drastis atau bahkan menjadi tidak berarti. Terdapat risiko kehilangan yang tinggi sebagai dampak dari membeli, menjual, atau berdagang apapun di pasar dan PT KRIPTO INOVASI NUSANTARA tidak bertanggung jawab atas perubahan fluktuasi dari nilai tukar Aset Kripto.</li>
                <li>Perdagangan Aset Kripto juga memiliki risiko tambahan yang tidak dialami oleh Aset Kripto atau komoditas lain di pasar. Tidak seperti mata uang kebanyakan yang dijamin oleh pemerintah atau lembaga legal lainnya, atau oleh emas dan perak, crypto asset merupakan sebuah Aset Kripto yang unik dan dijamin oleh teknologi dan rasa percaya. Tidak ada bank sentral yang dapat mengontrol, melindungi nilai Aset Kripto dalam krisis, atau mencetak mata uang tersebut.</li>
                <li>Member/Verified Member dihimbau untuk berhati-hati dalam mengukur situasi finansial dan memastikan bahwa Member/Verified Member bersedia menghadapi risiko yang ada dalam menjual, membeli, atau berdagang Aset Kripto. Member/Verified Member disarankan dengan sangat untuk melakukan riset pribadi sebelum membuat keputusan untuk memperjualbelikan Aset Kripto. Semua keputusan perdagangan Aset Kripto merupakan keputusan independen oleh pengguna secara sadar tanpa paksaan dan melepaskan PT KRIPTO INOVASI NUSANTARA atas kegiatan perdagangan Aset Kripto.</li>
                <li>PT KRIPTO INOVASI NUSANTARA tidak menjamin kelangsungan jangka panjang dari Aset Kripto yang diperdagangkan maupun ditukar di dalam marketplace.</li>
                <li>Berkenaan dengan kegiatan penambangan Aset Kripto perlu untuk diluruskan dan ditegaskan bahwa PT KRIPTO INOVASI NUSANTARA tidak melakukan kegiatan menambang, memproduksi atau mencetak Aset Kripto. Aset Kripto diciptakan dan ditambang menggunakan software khusus oleh para penambang (miner) yang tersebar secara acak di seluruh dunia dan saling terhubung dengan teknologi peer-to-peer di jaringan blockchain.</li>
            </ul>
        </li>
        <li>
            Larangan
            <ul>
                <li>Member/Verified Member tidak diperbolehkan melakukan tindakan-tindakan yang dapat mengakibatkan kerugian bagi PT KRIPTO INOVASI NUSANTARA dan atau yang bertentangan dengan PTT dan peraturan perundang-undangan yang berlaku.</li>
                <li>Apabila Member/Verified Member melanggar salah satu atau beberapa ketentuan dalam ketentuan ini, maka Member/Verified Member akan bertanggung jawab sepenuhnya dan dengan ini setuju bahwa atas pelanggaran tersebut PT KRIPTO INOVASI NUSANTARA berhak untuk membatasi fitur penggunaan layanan PT KRIPTO INOVASI NUSANTARA dan Member/Verified Member mengetahui dan setuju bahwa PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Member/Verified Member atau pihak mana pun atas segala resiko atau akibat yang timbul atas pembatasan penggunaan layanan tersebut. Member/Verified Member selanjutnya berkewajiban untuk membayar ganti rugi kepada PT KRIPTO INOVASI NUSANTARA sebesar nilai kerugian yang mungkin dialami PT KRIPTO INOVASI NUSANTARA atas pelanggaran tersebut. Member/Verified Member dengan ini Memberikan kuasa kepada PT KRIPTO INOVASI NUSANTARA untuk melakukan pendebetan Akun Member/Verified Member untuk pembayaran ganti rugi tersebut. Dalam hal dana pada Akun Member/Verified Member tidak tersedia dan atau tidak mencukupi, maka Member wajib mengembalikan seluruh dana tersebut secara tunai kepada PT KRIPTO INOVASI NUSANTARA dalam waktu selambat-lambatnya 7 (tujuh) hari kerja sejak PT KRIPTO INOVASI NUSANTARA mengajukan klaim dimaksud.</li>
            </ul>
        </li>
        <li>
            Kerahasiaan
            <ul>
                <li>Setiap informasi dan atau data yang diberikan oleh PT KRIPTO INOVASI NUSANTARA kepada Member/Verified Member dan atau data yang diperoleh Member/Verified Member sebagai pelaksanaan dari PTT baik yang diberikan atau disampaikan secara lisan, tertulis, grafik atau yang disampaikan melalui media elektronik atau informasi dan atau data dalam bentuk lainnya selama berlangsungnya pembicaraan atau selama pekerjaan lain adalah bersifat rahasia (selanjutnya disebut sebagai “Informasi Rahasia”).</li>
                <li>Member/Verified Member setuju dan sepakat bahwa setiap saat akan merahasiakan informasi rahasia yang diperoleh sebagai pelaksanaan dari kerja sama kepada siapa pun atau tidak akan menggunakannya untuk kepentingan Member/Verified Member atau kepentingan pihak lainnya, tanpa terlebih dahulu memperoleh persetujuan tertulis dari pejabat yang berwenang dari PT KRIPTO INOVASI NUSANTARA atau pihak yang berwenang lainnya sesuai dengan ketentuan hukum yang berlaku.</li>
                <li>Apabila Member/Verified Member melanggar ketentuan sebagaimana dimaksud dalam angka 1 dan 2 ketentuan mengenai kerahasiaan ini, maka segala kerugian, tuntutan dan atau gugatan yang dialami PT KRIPTO INOVASI NUSANTARA merupakan tanggung jawab Member/Verified Member sepenuhnya, dan atas permintaan pertama dari PT KRIPTO INOVASI NUSANTARA, Member/Verified Member berkewajiban untuk menyelesaikannya sesuai dengan ketentuan hukum dan perundang-undangan yang berlaku dan Memberikan ganti rugi yang mungkin timbul akibat pelanggaran tersebut kepada PT KRIPTO INOVASI NUSANTARA. Member/Verified Member dengan ini setuju bahwa PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apa pun kepada Member/Verified Member atau pihak mana pun atas segala tuntutan dan atau gugatan yang mungkin timbul dikemudian hari sehubungan dengan pelanggaran tersebut.</li>
                <li>Kewajiban untuk menyimpan informasi rahasia sebagaimana dimaksud dalam angka 1 dan 2 ketentuan mengenai kerahasiaan menjadi tidak berlaku, apabila:
                    <ul>
                        <li>Informasi rahasia tersebut menjadi tersedia untuk masyarakat umum.</li>
                        <li>Informasi rahasia tersebut diperintahkan untuk dibuka untuk memenuhi perintah pengadilan atau badan pemerintahan lain yang berwenang.</li>
                        <li>Informasi rahasia tersebut diberikan sesuai ketentuan hukum yang berlaku.</li>
                    </ul>
                </li>
                <li>Member/Verified Member selanjutnya menyetujui untuk melakukan segenap upaya dan mengambil setiap tindakan yang diperlukan untuk menghindari pihak-pihak ketiga dalam memperoleh akses terhadap atau mengakibatkan terjadinya pengungkapan atas informasi rahasia.</li>
                <li>Dalam hal kerjasama telah berakhir, Member/Verified Member sepakat bahwa kewajiban untuk merahasiakan dokumen dan materi yang merupakan informasi rahasia sebagaimana diatur dalam ketentuan ini akan tetap berlaku.</li>
            </ul>
        </li>
        <li>
            Kelalaian
            <ul>
                <li>Member/Verified Member sepakat bahwa yang dimaksud dengan Kelalaian (Wanprestasi) adalah hal atau peristiwa sebagai berikut:
                    <ul>
                        <li>Kelalaian (Wanprestasi).</li>
                        <li>Apabila Member/Verified Member lalai dalam melaksanakan sesuatu kewajiban atau melanggar suatu ketentuan dalam PTT.</li>
                        <li>Pernyataan Tidak Benar.</li>
                        <li>Bilamana ternyata bahwa suatu pernyataan atau jaminan yang diberikan oleh Member/Verified Member dalam PTT – tidak benar atau tidak sesuai dengan kenyataannya.</li>
                    </ul>
                </li>
                <li>Dalam hal terjadi suatu kejadian kelalaian berdasarkan angka 1 ketentuan kelalaian ini oleh Member/Verified Member, maka PT KRIPTO INOVASI NUSANTARA dapat memilih apakah tetap meneruskan atau menutup Akun Member/Verified Member. Apabila PT KRIPTO INOVASI NUSANTARA berkehendak untuk menutup Akun Member/Verified Member, maka kehendak tersebut harus diberitahukan kepada Member/Verified Member dalam waktu yang wajar menurut PT KRIPTO INOVASI NUSANTARA.</li>
                <li>Dalam hal terjadi kejadian kelalaian oleh Member/Verified Member sebagaimana dimaksud, maka Website/Trading Platform PT KRIPTO INOVASI NUSANTARA berhak dengan seketika melakukan penonaktifan Akun Member/Verified Member pada PT KRIPTO INOVASI NUSANTARA tanpa harus melakukan pemberitahuan terlebih dahulu kepada Member/Verified Member, dan Member/Verified Member dengan ini setuju bahwa PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apa pun kepada Member/Verified Member atau pihak mana pun atas segala tuntutan dan atau gugatan dan atau klaim dan atau permintaan ganti kerugian dari pihak mana pun yang mungkin timbul sehubungan dengan terjadinya kelalaian tersebut.</li>
            </ul>
        </li>
        <li>
            Hak Kekayaan Intelektual
            <ul>
                <li>Member/Verified Member menyatakan dan menyetujui PT KRIPTO INOVASI NUSANTARA sebagai pemegang hak kepemilikan atas layanan, perangkat lunak, alat teknologi dan konten, situs, dan bahan lain termasuk Hak Kekayaan Intelektual yang terkait dengan fasilitas PT KRIPTO INOVASI NUSANTARA.</li>
                <li>Member/Verified Member hanya diperbolehkan untuk melihat, mencetak dan/atau mengunduh kopi material dari Website/Trading Platform untuk penggunaan pribadi dan non-komersial. Seluruh penggunaan komersial perlu mendapatkan ijin dari PT KRIPTO INOVASI NUSANTARA. Setiap kegiatan komersial tanpa seizin PT KRIPTO INOVASI NUSANTARA diartikan sebagai pelanggaran atas Hak Kekayaan Intelektual PT KRIPTO INOVASI NUSANTARA dan mengakibatkan pemberhentian Akun PT KRIPTO INOVASI NUSANTARA pada Member/Verified Member.</li>
            </ul>
        </li>
        <li>
            Pemblokiran dan Pembekuan Akun Member / Verified Member
            <ul>
                <li>Dalam rangka menjalankan prinsip kehati-hatian, PT KRIPTO INOVASI NUSANTARA berhak dan Member/Verified Member dengan ini Memberi kuasa kepada PT KRIPTO INOVASI NUSANTARA untuk memblokir baik sebagian atau seluruh saldo Member/Verified Member (hold amount) dan/atau mendebitnya serta melakukan pemberhentian Akun, apabila terjadi hal berikut:
                    <ul>
                        <li>Adanya permintaan dari pihak perbankan dikarenakan adanya kesalahan pengiriman dana dan pihak perbankan tersebut meminta dilakukan pemblokiran;</li>
                        <li>Menurut pendapat dan pertimbangan PT KRIPTO INOVASI NUSANTARA terdapat kesalahan penerimaan transaksi;</li>
                        <li>PT KRIPTO INOVASI NUSANTARA digunakan sebagai tempat penampungan yang diindikasikan hasil kejahatan termasuk namun tidak terbatas pada Tindak Pidana korupsi, penyuapan, narkotika, psikotropika, penyelundupan tenaga kerja, penyelundupan manusia, bidang perbankan, bidang pasar modal, bidang perasuransian, kepabeanan, cukai, perdagangan manusia, perdagangan senjata gelap, terorisme, penculikan, pencurian, penggelapan, penipuan, pemalsuan, perjudian, prostitusi, bidang perpajakan, dan terorisme;</li>
                    </ul>
                </li>
                <li>Member/Verified Member dinilai lalai dalam melakukan kewajiban berdasarkan PTT;</li>
                <li>Kebijakan PT KRIPTO INOVASI NUSANTARA atau oleh suatu ketetapan pemerintah atau instansi yang berwenang atau peraturan yang berlaku, sehingga PT KRIPTO INOVASI NUSANTARA diharuskan melakukan pemblokiran dan atau pembekuan Akun PT KRIPTO INOVASI NUSANTARA tersebut.</li>
                <li>Apabila terjadi pemberhentian Akun, Member/Verified Member setuju dan menyatakan bersedia untuk tetap terikat dengan PTT menghentikan penggunaan layanan PT KRIPTO INOVASI NUSANTARA, Memberikan hak kepada Website/Trading Platform PT KRIPTO INOVASI NUSANTARA untuk menghapus semua informasi dan data dalam server PT KRIPTO INOVASI NUSANTARA, dan menyatakan PT KRIPTO INOVASI NUSANTARA tidak bertanggung jawab kepada Member/Verified Member atau Pihak Ketiga atas penghentian akses dan penghapusan informasi serta data dalam Akun Member/Verified Member.</li>
            </ul>
        </li>
        <li>
            Pajak
            <ul>
                <li>Bahwa pajak atas aktivitas perdagangan Aset Kripto adalah pajak yang ditanggung oleh masing-masing pelaku kegiatan, dalam hal ini Member/Verified Member dan PT KRIPTO INOVASI NUSANTARA. Member/Verified Member dapat mengkonsultasikan pembayaran pajak kepada Konsultan Pajak Pribadi, dan PT KRIPTO INOVASI NUSANTARA tidak menanggung pajak Member/Verified Member kecuali ditentukan lain dalam Syarat dan Ketentuan ini.</li>
            </ul>
        </li>
    </ol>

    <h2>V. PENGKINIAN DATA PELANGGAN</h2>
    <ol>
        <li>
            Perubahan Nama Akun Member/Verified Member
            <ul>
                <li>Perubahan Nama Akun Member/Verified Member dapat dilakukan dengan mekanisme yang diwajibkan untuk mengirimkan nama lama dan nama baru pemilik Akun, username, e-mail, nomor telepon, dan nomor kartu identitas yang digunakan (KTP/Passport/KITAS/KITAP). PT KRIPTO INOVASI NUSANTARA akan melakukan proses pencocokan data dan akan melakukan konfirmasi ke nomor telepon yang terdaftar di Akun Member/Verified Member untuk menghindari terjadinya penipuan. Jika proses perubahan telah terlewati maka nama Akun akan berubah sesuai keinginan Member/Verified Member. Berkenaan dengan perubahan nama Akun ini, Contoh alasan yang diperbolehkan adalah: ejaan nama salah, calon Member/Verified Member diwajibkan untuk mengirimkan softcopy Kartu Tanda Pengenal (KTP) ke email support yang tertera dalam Website/Trading Platform dengan Subjek ‘Data Kelengkapan Penggantian Nama Akun’.</li>
            </ul>
        </li>
        <li>
            Perubahan Data Pendukung
            <ul>
                <li>Perubahan dan Pembaharuan Data Pendukung wajib dilakukan oleh Member/Verified Member terhadap data berupa nomor telepon, alamat e- mail dan data pendukung lainnya sesuai dengan kondisi terkini. Hal ini sesuai dengan prinsip KYC (Know Your Customer) Principles yang diterapkan oleh PT KRIPTO INOVASI NUSANTARA. Perubahan dan Pembaharuan Data dilakukan dengan melakukan pengiriman e-mail dengan subjek ‘Perubahan Data’ ke email support yang tertera dalam Website dengan menuliskan data apa yang ingin disesuaikan oleh Member/Verified Member disertai alasan perubahan. Proses perubahan wajib disertai dengan pencantuman softcopy identitas diri, username, nama lengkap, alamat, e-mail, data lama yang ingin diubah dan data perubahannya. Untuk selanjutnya, PT KRIPTO INOVASI NUSANTARA akan Memberikan notifikasi dengan menghubungi nomor telepon utama atau nomor telepon alternatif yang telah terdaftar.</li>
            </ul>
        </li>
        <li>
            Perubahan Password
            <ul>
                <li>Perubahan Password dimungkinkan sebagai alasan keamanan dan mekanismenya telah tersedia dalam layanan. Demi keamanan setelah mengganti password, Akun Member/Verified Member akan terkunci selama 48 (empat puluh delapan) jam. Member/Verified Member tidak dapat melakukan penarikan dana pada saat Akun dikunci meskipun transaksi lain berjalan seperti biasa. Member/Verified Member dapat membuka kembali Akun Member/Verified Member dengan memasukkan kembali PIN Google Authenticator atau verifikasi PIN SMS pada menu ‘Keamanan’.</li>
            </ul>
        </li>
    </ol>

    <h2>VI. TATA CARA KEGIATAN TRANSAKSI</h2>
    <p>Website memperkenankan Verified Member untuk melakukan kegiatan perdagangan pada Website mengenai:</p>
    <ol>
        <li>Pembelian Aset Kripto dengan mata uang Rupiah. Pembelian Aset Kripto dengan mata uang rupiah yang telah didepositkan kedalam Website/Aplikasi Trading oleh Pelanggan Aset Kripto.</li>
        <li>Penjualan Aset Kripto dengan mata uang Rupiah. Penjualan Aset Kripto dengan mata uang rupiah yang dimiliki oleh Pelanggan Aset Kripto.</li>
        <li>Melakukan deposit uang dalam mata uang Rupiah. Deposit dana mata uang Rupiah oleh Pelanggan Aset Kripto sebelum melakukan transaksi pembelian Aset Kripto.</li>
        <li>Melakukan penarikan dalam mata uang Rupiah. Penarikan dana mata uang Rupiah oleh Pelanggan Aset Kripto dan ditujukan kepada rekening Bank yang telah terdaftar pada website/aplikasi trading platform PT KRIPTO INOVASI NUSANTARA.</li>
        <li>Melakukan pengiriman Aset Kripto kepada Member/Verified Member lain di dalam Website, maupun pengguna Aset Kripto di luar Website. Pengiriman Aset Kripto kepada Pelanggan lain baik sesama Pelanggan maupun bukan pelanggan dari PT KRIPTO INOVASI NUSANTARA.</li>
        <li>Menerima deposit dalam berbagai bentuk Aset Kripto. Verified Member dapat menerima Aset Kripto yang dikirim oleh Pelanggan baik sesama Pelanggan maupun bukan pelanggan dari PT KRIPTO INOVASI NUSANTARA.</li>
    </ol>

    <p>Metode – metode dalam tata cara transaksi:</p>
    <ul>
        <li>
            <b>Metode Order Book:</b>
            <p>Merupakan metode dalam layanan dimana Verified Member dapat menentukan harga jual/ beli dalam melakukan transaksi perdagangan Aset Kripto baik menggunakan Rupiah maupun Aset Kripto.</p>
        </li>
        <li>
            <b>Metode Instan Order:</b>
            <p>Merupakan metode dalam layanan dimana Verified Member dapat melakukan pembelian/penjualan Aset Kripto dengan Rupiah, secara instan atau langsung dengan membeli pada titik kesepakatan yang terdapat dalam marketplace tanpa perlu menunggu harga menyentuh titik nominal yang diinginkan.</p>
        </li>
    </ul>

    <h3>Contoh Skema Transaksi Beli dengan order book:</h3>
    <p>Kondisi awal</p>
    <p>Sudah ada order book jual dalam antrian, misalnya :</p>
    <p>ORDER BOOK JUAL KOIN ABC</p>
    <table>
        <thead>
            <tr>
                <th>No</th>
                <th>Nama Coin</th>
                <th>Harga Jual 1 Coin</th>
                <th>Volume Coin</th>
                <th>Jumlah Rupiah</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>5</td>
                <td>Rp. 32.500,-</td>
            </tr>
            <tr>
                <td>2</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>10</td>
                <td>Rp. 65.000,-</td>
            </tr>
            <tr>
                <td>3</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>10</td>
                <td>Rp. 65.000,-</td>
            </tr>
            <tr>
                <td>4</td>
                <td>ABC</td>
                <td>Rp. 8.000,-</td>
                <td>10</td>
                <td>Rp. 70.000,-</td>
            </tr>
            <tr>
                <td>5</td>
                <td>ABC</td>
                <td>Rp. 8.000,-</td>
                <td>15</td>
                <td>Rp. 105.000,-</td>
            </tr>
        </tbody>
    </table>

    <p>ORDER BOOK BELI KOIN ABC</p>
    <table>
        <thead>
            <tr>
                <th>No</th>
                <th>Nama Coin</th>
                <th>Harga Jual 1 Coin</th>
                <th>Volume Coin</th>
                <th>Jumlah Rupiah</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>5</td>
                <td>Rp. 25.000,-</td>
            </tr>
            <tr>
                <td>2</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>10</td>
                <td>Rp. 50.000,-</td>
            </tr>
            <tr>
                <td>3</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>10</td>
                <td>Rp. 50.000,-</td>
            </tr>
            <tr>
                <td>4</td>
                <td>ABC</td>
                <td>Rp. 5.500,-</td>
                <td>10</td>
                <td>Rp. 45.000,-</td>
            </tr>
            <tr>
                <td>5</td>
                <td>ABC</td>
                <td>Rp. 5.500,-</td>
                <td>15</td>
                <td>Rp. 67.500,-</td>
            </tr>
        </tbody>
    </table>
    <p>Ada Pengguna (member) yang ingin membeli coin ABC sebanyak 10 coin dengan harga per coin yang diharapkannya RP. 7.500,-</p>
    <p>Pada saat transaksi beli member diatas dilakukan, karena ada data order book jual pada tabel 1 dengan harga jual 1 coin dan jumlah coin yang dijual sama dengan yang akan dibeli oleh member, maka transaksi langsung berhasil dan tidak masuk kedalam order book dalam antrian.</p>
    <p>Pada tabel 1 ada 2 data yang matching dengan yang akan dibeli yaitu data nomor 2 dan nomor 3. Data diurutkan berdasarkan tanggal dan jam (timestamp) yang paling awal masuk, sehingga transaksi yang matching adalah transaksi jual nomor 2, sehingga transaksi beli langsung berhasil dan data nomor 2 pada tabel 1 terjual duluan dan hilang dari daftar antrian jual.</p>
    <p>Apabila ada Pengguna (member) yang ingin membeli coin ABC sebanyak 10 coin dengan harga per coin Rp. 6.500,- karena angka tersebut tidak ada dalam Oder Book Jual, maka permintaan tersebut akan masuk ke Order Book Beli</p>

    <p>Kondisi Akhir:</p>

    <p>ORDER BOOK JUAL KOIN ABC</p>
    <table>
        <thead>
            <tr>
                <th>No</th>
                <th>Nama Coin</th>
                <th>Harga Jual 1 Coin</th>
                <th>Volume Coin</th>
                <th>Jumlah Rupiah</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>5</td>
                <td>Rp. 32.500,-</td>
            </tr>
            <tr>
                <td>2</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>10</td>
                <td>Rp. 65.000,-</td>
            </tr>
            <tr>
                <td>3</td>
                <td>ABC</td>
                <td>Rp. 8.000,-</td>
                <td>10</td>
                <td>Rp. 70.000,-</td>
            </tr>
            <tr>
                <td>4</td>
                <td>ABC</td>
                <td>Rp. 8.000,-</td>
                <td>15</td>
                <td>Rp. 105.000,-</td>
            </tr>
        </tbody>
    </table>

    <p>ORDER BOOK BELI KOIN ABC</p>
    <table>
        <thead>
            <tr>
                <th>No</th>
                <th>Nama Coin</th>
                <th>Harga Jual 1 Coin</th>
                <th>Volume Coin</th>
                <th>Jumlah Rupiah</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>ABC</td>
                <td>Rp. 6.500,-</td>
                <td>10</td>
                <td>Rp. 55.000,-</td>
            </tr>
            <tr>
                <td>2</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>5</td>
                <td>Rp. 15.000,-</td>
            </tr>
            <tr>
                <td>3</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>10</td>
                <td>Rp. 50.000,-</td>
            </tr>
            <tr>
                <td>4</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>10</td>
                <td>Rp. 50.000,-</td>
            </tr>
            <tr>
                <td>5</td>
                <td>ABC</td>
                <td>Rp. 5.500,-</td>
                <td>10</td>
                <td>Rp. 45.000,-</td>
            </tr>
            <tr>
                <td>6</td>
                <td>ABC</td>
                <td>Rp. 5.500,-</td>
                <td>15</td>
                <td>Rp. 45.000,-</td>
            </tr>
        </tbody>
    </table>

    <h3>Contoh Skema Transaksi Jual dengan order book:</h3>
    <p>Kondisi awal</p>
    <p>Sudah ada order book beli dalam antrian, misalnya :</p>
    <br>
    <p>ORDER BOOK BELI KOIN ABC</p>
    <table>
        <thead>
            <tr>
                <th>No</th>
                <th>Nama Coin</th>
                <th>Harga Jual 1 Coin</th>
                <th>Volume Coin</th>
                <th>Jumlah Rupiah</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>5</td>
                <td>Rp. 25.000,-</td>
            </tr>
            <tr>
                <td>2</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>10</td>
                <td>Rp. 50.000,-</td>
            </tr>
            <tr>
                <td>3</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>10</td>
                <td>Rp. 50.000,-</td>
            </tr>
            <tr>
                <td>4</td>
                <td>ABC</td>
                <td>Rp. 5.500,-</td>
                <td>10</td>
                <td>Rp. 45.000,-</td>
            </tr>
            <tr>
                <td>5</td>
                <td>ABC</td>
                <td>Rp. 5.500,-</td>
                <td>15</td>
                <td>Rp. 67.500,-</td>
            </tr>
        </tbody>
    </table>

    <br>
    <p>ORDER BOOK JUAL KOIN ABC</p>
    <table>
        <thead>
            <tr>
                <th>No</th>
                <th>Nama Coin</th>
                <th>Harga Jual 1 Coin</th>
                <th>Volume Coin</th>
                <th>Jumlah Rupiah</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>5</td>
                <td>Rp. 32.500,-</td>
            </tr>
            <tr>
                <td>2</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>10</td>
                <td>Rp. 65.000,-</td>
            </tr>
            <tr>
                <td>3</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>10</td>
                <td>Rp. 65.000,-</td>
            </tr>
            <tr>
                <td>4</td>
                <td>ABC</td>
                <td>Rp. 8.000,-</td>
                <td>10</td>
                <td>Rp. 70.000,-</td>
            </tr>
            <tr>
                <td>5</td>
                <td>ABC</td>
                <td>Rp. 8.000,-</td>
                <td>15</td>
                <td>Rp. 105.000,-</td>
            </tr>
        </tbody>
    </table>
    <p>Ada Pengguna yang ingin menjual coin ABC sebanyak 10 coin dengan harga per coin yang diharapkannya RP. 6.000,-</p>
    <p>Pada saat transaksi jual diatas dilakukan, karena ada data order book beli pada tabel 1 dengan harga jual 1 coin dan jumlah coin yang dijual sama dengan yang akan dijual, maka transaksi langsung berhasil dan tidak masuk kedalam order book dalam antrian.</p>
    <p>Pada tabel 1 ada 2 data yang matching dengan yang akan dijual yaitu data nomor 1 dan nomor 2. Data diurutkan berdasarkan tanggal dan jam (timestamp) yang paling awal masuk, sehingga transaksi yang matching adalah transaksi jual nomor 2, sehingga transaksi jual langsung berhasil dan data nomor 2 pada tabel 1 terjual duluan dan hilang dari daftar antrian beli.</p>
    <p>Apabila ada Pengguna (member) yang ingin menjual coin ABC sebanyak 10 coin dengan harga per coin Rp. 6.500,- karena angka tersebut tidak ada dalam Oder Book Beli, maka permintaan tersebut akan masuk ke Order Book Jual</p>
    <p>Kondisi Akhir</p>

    <br>
    <p>ORDER BOOK BELI KOIN ABC</p>
    <table>
        <thead>
            <tr>

                <th>No</th>
                <th>Nama Coin</th>
                <th>Harga Jual 1 Coin</th>
                <th>Volume Coin</th>
                <th>Jumlah Rupiah</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>5</td>
                <td>Rp. 25.000,-</td>
            </tr>
            <tr>
                <td>2</td>
                <td>ABC</td>
                <td>Rp. 6.000,-</td>
                <td>10</td>
                <td>Rp. 50.000,-</td>
            </tr>
            <tr>
                <td>3</td>
                <td>ABC</td>
                <td>Rp. 5.500,-</td>
                <td>10</td>
                <td>Rp. 45.000,-</td>
            </tr>
            <tr>
                <td>4</td>
                <td>ABC</td>
                <td>Rp. 5.500,-</td>
                <td>15</td>
                <td>Rp. 67.500,-</td>
            </tr>
        </tbody>
    </table>

    <br>
    <p>ORDER BOOK JUAL KOIN ABC</p>
    <table>
        <thead>
            <tr>
                <th>No</th>
                <th>Nama Coin</th>
                <th>Harga Jual 1 Coin</th>
                <th>Volume Coin</th>
                <th>Jumlah Rupiah</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1</td>
                <td>ABC</td>
                <td>Rp. 6.500,-</td>
                <td>10</td>
                <td>Rp. 55.000,-</td>
            </tr>
            <tr>
                <td>2</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>5</td>
                <td>Rp. 32.500,-</td>
            </tr>
            <tr>
                <td>3</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>10</td>
                <td>Rp. 65.000,-</td>
            </tr>
            <tr>
                <td>4</td>
                <td>ABC</td>
                <td>Rp. 7.500,-</td>
                <td>10</td>
                <td>Rp. 65.000,-</td>
            </tr>
            <tr>
                <td>5</td>
                <td>ABC</td>
                <td>Rp. 8.000,-</td>
                <td>10</td>
                <td>Rp. 70.000,-</td>
            </tr>
            <tr>
                <td>6</td>
                <td>ABC</td>
                <td>Rp. 8.000,-</td>
                <td>15</td>
                <td>Rp. 105.000,-</td>
            </tr>
        </tbody>
    </table>

    <h3>Jenis aset kripto</h3>
    <p>Jumlah Aset Kripto yang diperdagangkan pada aplikasi PT Kripto Inovasi Nusantara yaitu sebanyak 383 jenis aset kripto yang diatur dalam ketentuan Peraturan Kepala Bappebti Nomor 11 Tahun 2022.</p>

    <h3>Tata cara kegiatan transaksi meliputi :</h3>
    <ol>
        <li>Transaksi Jual / beli
            <ol>
                <li>Member login ke dalam system.</li>
                <li>Masuk ke menu Market.</li>
                <li>Melakukan inisiasi jual/beli.</li>
                <li>Sistem melakukan validasi atas kecukupan dana maupun nilai Aset Kripto ke database PT KRIPTO INOVASI NUSANTARA.</li>
                <li>Jika lolos verifikasi maka system akan melakukan proses matching.</li>
                <li>Untuk transaksi yang matching data dicatat ke dalam database PT KRIPTO INOVASI NUSANTARA.</li>
                <li>Mengirimkan konfirmasi hasil matching kepada Member.</li>
            </ol>
        </li>
        <li>Deposit
            <ol>
                <li>Member masuk ke dalam aplikasi web/mobile dengan menggunakan username & password.</li>
                <li>Masuk ke menu Deposit IDR kemudian memasukkan jumlah rupiah yang akan di setorkan, kemudian memilih rekening tujuan transfer kemudian submit. untuk mendapatkan tiket setoran rincian nilai dan no Virtual Account.</li>
                <li>Member akan mendapatkan tiket setoran berisi rincian nilai, Bank Tujuan dan no Virtual Account.</li>
                <li>Member melakukan Setoran via transfer sesuai tiket.</li>
                <li>Sistem Perusahaan akan secara otomatis dan berkala melakukan pengecekan mutasi pada rek VA pada masing-masing Bank secara real time.</li>
                <li>Jika transaksi setoran ditemukan maka secara otomatis system akan melakukan update ke dalam wallet IDR Member. Member bisa melakukan pengecekan untuk memastikan setoran telah masuk dan menambah wallet IDR.</li>
                <li>Sistem akan mengirimkan email konfirmasi bahwa Setoran Dana Member telah diterima.</li>
            </ol>
        </li>
        <li>Withdrawal
            <ol>
                <li>Member login ke dalam system.</li>
                <li>Masuk ke menu Withdrawal IDR, kemudian memasukkan nilai dana yang akan ditarik dan memilih Rekening Tujuan sesuai yang tercatat dalam system. (system akan menolak jika nama pada rekening tujuan berbeda dengan nama pemilik akun).</li>
                <li>Sistem melakukan validasi atas kecukupan dana yang tersedia pada wallet IDR.</li>
                <li>Jika telah sesuai, sistem akan menjalankan perintah transfer Aset Kripto.</li>
                <li>Setelah transfer dana selesai dilakukan, maka system akan mengirimkan konfirmasi melalui email yang telah terdaftar milik member.</li>
            </ol>
        </li>
        <li>Pengiriman aset kripto ke wallet lain
            <ol>
                <li>Member login ke dalam system.</li>
                <li>Masuk ke menu Withdrawal Aset Kripto, kemudian memasukkan nilai Aset Kripto yang akan ditarik dan memilih alamat Wallet Tujuan sesuai yang tercatat dalam system.</li>
                <li>Sistem melakukan validasi atas kecukupan nilai Aset Kripto yang tersedia pada wallet aset Kripto.</li>

                <li>Jika telah sesuai, system akan menjalankan proses Know Your Transaction dan Travel Rule berdasarkan data pengirim dan penerima sesuai dengan aturan Bappebti</li>
                <li>Apabila sudah sesuai, maka system akan melaksanakan transfer aset kripto</li>
                <li>Setelah transfer aset kripto selesai dilakukan, maka system akan mengirimkan melalui email yang telah terdaftar milik member.</li>
                <li>Kegiatan lain yang telah mendapat persetujuan dari Bappebti. PT Kripto Inovasi Nusantara saat ini belum menjalankan kegiatan lainnya selain kegiatan yang disebutkan dalam dokumen ini</li>
            </ol>
        </li>
    </ol>

    <h2>VII. BIAYA TRANSAKSI DAN BATAS PENARIKAN</h2>
    <p>Biaya transaksi dalam PT KRIPTO INOVASI NUSANTARA adalah sebagai berikut:</p>
    <ul>
        <li>Maker dan Taker baik untuk transaksi jual maupun  beli akan dikenakan biaya transaksi sebesar 0,6% dari jumlah transaksi</li>
        <li>Biaya transaksi sudah termasuk PPN sebesar 0,11% untuk pembeli dan PPh sebesar 0,1% untuk penjual dari jumlah transaksi</li>
        <li>Biaya dapat berubah sewaktu-waktu berdasarkan kebijakan dari PT KRIPTO INOVASI NUSANTARA dengan pemberitahuan terlebih dahulu kepada seluruh Pelanggan.</li>
    </ul>

    <p>Batas penarikan dalam PT KRIPTO INOVASI NUSANTARA adalah sebagai berikut:</p>
    <ul>
        <li>Member/Verified Member dengan ini menyatakan setuju untuk mematuhi batas penarikan baik terhadap Aset Kripto maupun Rupiah yang berlaku terhadap Akun Member/Verified Member, dan PT KRIPTO INOVASI NUSANTARA yang dalam hal ini menerapkan prinsip Anti Money Laundering (AML) sesuai dengan regulasi Pemerintah Republik Indonesia diberi kuasa serta hak untuk tidak melakukan proses terhadap transaksi yang telah melebihi batas penarikan harian yaitu sebesar yang ditentukan oleh PT KRIPTO INOVASI NUSANTARA.</li>
        <li>Batas penarikan dana/aset kripto untuk Warga Negara Indonesia (WNI) ekuivalen Rp100.000.000,- (Seratus Juta Rupiah).</li>
        <li>Batas penarikan dana/aset kripto untuk Warga Negara Asing (WNA) ekuivalen Rp10.000.000,- (Sepuluh Juta Rupiah).</li>
        <li>Batas penarikan direset ulang atau kembali pada angka 0 (nol) setiap pukul 00.00 Waktu Indonesia Barat (WIB).</li>
        <li>Penambahan batas penarikan dapat dilakukan dengan mengajukan permohonan melalui menu penambahan limit pada Website, dimana Member/Verified Member wajib melakukan pengisian, pencantuman dan pencetakan terhadap semua informasi yang terdapat dalam formulir di atas materai Rp. 10.000,- (sepuluh ribu rupiah) dan Member/Verified Member wajib untuk mengirimkan soft copy terhadap dokumen tersebut baik melalui foto atau hasil scan, serta memastikan tulisan dapat dibaca dengan jelas, tidak terdapat crop, sensor atau rekayasa digital dalam bentuk apa pun; ke email support yang tertera dalam Website dengan subjek “Permohonan Kenaikan Limit Penarikan”.</li>
        <li>Proses persetujuan atau penolakan kenaikan batas penarikan limit harian mutlak merupakan kewenangan PT KRIPTO INOVASI NUSANTARA dengan pertimbangan dari tim audit dan hukum sesuai permintaan, sejarah transaksi, sumber dana, dan tujuan penggunaan transaksi serta tidak dapat diintervensi. Member/Verified Member yang ditolak kenaikan limitnya baru dapat mengajukan permohonan kembali dengan jangka waktu 1×24 (satu kali dua puluh empat) jam.</li>
    </ul>

    <h2>VIII. KEAMANAN TRANSAKSI</h2>
    <p>PT KRIPTO INOVASI NUSANTARA telah menerapkan jaringan dan tindakan keamanan sebagai jaringan pengaman informasi terhadap akses yang tidak sah dalam penggunaan, perubahan dan/atau pengungkapan Akun, dengan standar keamanan yang telah sesuai dengan Ketentuan Peraturan Perundang-undangan yang berlaku. Masuknya pihak bertanggung jawab terhadap akses dalam penggunaan, perubahan dan/atau pengungkapan Akun dari pihak ketiga akibat kelalaian dan/atau kesalahan Member/Verified Member berakibat ditanggungnya risiko oleh Member/Verified Member, maka PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apa pun kepada Member/Verified Member atau pihak lain manapun atas segala risiko, tanggung jawab dan tuntutan apapun yang mungkin timbul sehubungan dengan adanya kelalaian/kesengajaan/kesalahan Member/Verified Member dalam Memberikan informasi.</p>


    <h2>IX. LAYANAN PENGADUAN PELANGGAN ASET KRIPTO</h2>
    <p>Pengaduan Member/Verified Member disini terkait dengan pemberitahuan pelanggaran data dan/atau apabila terjadi permasalahan berkenaan dengan kepemilikan Aset Kripto yang dimiliki oleh Member/Verified Member. Apabila terjadi permasalahan tersebut, berikut mekanisme yang harus dilakukan oleh Member/Verified Member antara lain:</p>
    <ol>
        <li>Berkenaan dengan pengaduan Member/Verified Member, Member/Verified Member memiliki hak untuk melakukan pemberitahuan kepada PT KRIPTO INOVASI NUSANTARA dengan kondisi sebagai berikut:
            <ul>
                <li>Apabila Member/Verified Member menerima spam, segala bentuk iklan liar dan/atau surat elektronik yang mengatasnamakan nama selain PT KRIPTO INOVASI NUSANTARA yang meminta data pribadi dan/atau mengganggu kenyamanan para Member/Verified Member;</li>
                <li>Apabila terdapat praktik tindak kejahatan pencurian dan penipuan Aset Kripto yang mengakibatkan hilangnya Aset Kripto di Akun Member/Verified Member;</li>
                <li>Apabila terdapat dugaan aktivitas pelanggaran data Member/Verified Member oleh pihak lain yang bukan dijelaskan pada poin a dan b tersebut diatas.</li>
            </ul>
        </li>
        <li>Member/Verified Member dapat mengajukan pengaduan dengan mengisi formulir pengaduan yang disediakan oleh PT KRIPTO INOVASI NUSANTARA. Form pengaduan dapat langsung diunduh di menu pengaduan dalam Website yang kami sediakan atau dapat diunduh disini;</li>
        <li>Berkenaan dengan praktik penyalahgunaan data Member/Verified Member oleh pihak lain yang mengakibatkan adanya spam atau praktik skimming, maka Member/Verified Member dimohon dapat melakukan pengaduan dengan mekanisme sebagai berikut:
            <ul>
                <li>Sebagaimana yang diisyaratkan pada poin 2, Member/Verified Member dapat melakukan pengaduan melalui pengisian form pengaduan yang telah kami sediakan dan dapat mengirimkan ke email support yang tertera dalam Website;</li>
                <li>Laporan tersebut akan segera kami lakukan tindak lanjut dengan penanganan pengaduan dalam kurun waktu 3 x 24 jam;</li>
                <li>Apabila dalam kurun waktu tersebut, Member/Verified Member tidak mendapatkan pelayanan dari pihak kami, maka Member/Verified Member dapat mengirimkan kembali form pengaduan tersebut ke email support yang tertera dalam Website dan/atau melakukan kontak melalui kantor representatif PT KRIPTO INOVASI NUSANTARA.</li>
            </ul>
        </li>
        <li>Berkenaan dengan hilangnya Aset Kripto di Akun Member/Verified Member sebagai akibat adanya praktik tindak pidana kejahatan elektronik oleh pihak lain, maka Member/Verified Member dapat melakukan pengaduan dengan mekanisme sebagai berikut:
            <ul>
                <li>Sebagaimana yang diisyaratkan pada poin 2, Member/Verified Member dapat melakukan pengaduan melalui pengisian form pengaduan yang telah disediakan;</li>
                <li>Laporan tersebut akan dilaksanakan penanganan awal dimana akan dilakukan pembekuan Akun oleh pihak terlapor (pihak terduga) yang menampung atau mengambil Aset Kripto Member/Verified Member tersebut;</li>
                <li>Admin akan meneruskan laporan pengaduan Anda kepada tim legal untuk memproses penanganan selanjutnya. Penanganan selanjutnya berupa klarifikasi dengan pihak terlapor (pihak terduga);</li>
                <li>Apabila tahap klarifikasi ini berhasil dimana pihak terlapor Memberikan respons maka akan dilakukan mediasi dengan pihak pelapor (dalam hal ini Member/Verified Member yang melapor);</li>
                <li>Apabila mediasi tidak berhasil maka akan dilanjutkan melalui laporan ke kepolisian setempat dan kami akan mempersiapkan data yang diminta.</li>
            </ul>
        </li>
        <li>Pengaduan Nasabah dapat menghubungi kami melalui alamat sebagai berikut:
            <ul>
                <li>e-mail : info@coinx.co.id</li>
                <li>whatsapp : 081380833921</li>
            </ul>
        </li>
    </ol>

    <h2>X. LAYANAN PENDUKUNG APLIKASI</h2>
    <p>PT KRIPTO INOVASI NUSANTARA menyediakan layanan dalam bentuk Mobile Application (selanjutnya disebut sebagai “Aplikasi Seluler”), dengan ketentuan sebagai berikut:</p>
    <ol>
        <li>Dalam perihal penggunaan Aplikasi Seluler oleh Member/Verified Member dan pengguna, PT KRIPTO INOVASI NUSANTARA berhak untuk meminta dan mengumpulkan informasi berkenaan dengan perangkat seluler yang digunakan Member/Verified Member untuk mengakses Aplikasi Seluler yang disediakan termasuk namun tidak terbatas untuk perangkat keras, sistem operasi, pengenal perangkat unik, informasi jaringan seluler juga termasuk data pribadi seperti alamat surat elektronik, alamat, nomor telepon seluler, alias, kata sandi, kode PIN dan informasi lainnya yang diperlukan untuk menggunakan layanan aplikasi seluler ini;</li>
        <li>Aplikasi Seluler ini telah diuji oleh para peneliti dan Pengembang PT KRIPTO INOVASI NUSANTARA dan didalam penggunaannya, PT KRIPTO INOVASI NUSANTARA menghimbau untuk berhati-hati didalam pemakaiannya dan PT KRIPTO INOVASI NUSANTARA tidak dapat menjamin performa dan Aplikasi Seluler ini selalu berjalan dengan hasil yang dikehendaki;</li>
        <li>PT KRIPTO INOVASI NUSANTARA dan Pengembang tidak bertanggung jawab dan tidak terikat untuk segala bentuk keuntungan maupun kerugian yang dimungkinkan terjadi sebagai akibat dari penggunaan Aplikasi Seluler ini. Keuntungan yang dimaksud mengacu pada bertambahnya jumlah saldo dalam bentuk format Aset Kripto ataupun Rupiah. Sedangkan untuk kerugian yang dimaksud, hal tersebut termasuk namun tidak terbatas untuk: berkurangnya jumlah saldo dalam format Aset Kripto dan/atau Rupiah, kegagalan untuk menjalankan API dan segala perintahnya, masalah jaringan/sinyal, terjadinya error pada sistem atau error yang disebabkan karena faktor-faktor dalam bentuk lain;</li>
        <li>Dalam pemakaian Aplikasi Seluler ini, Member/Verified Member ataupun Pengguna telah menyetujui bahwa dalam kondisi apa pun, Member/Verified Member dilarang untuk: menggandakan, melakukan penyalinan, memproduksi ulang, menerjemahkan, mengganti sistem, memodifikasi, melepas pemasangan, melepas susunan atau mencoba untuk menderivasikan kode sumber dari produk perangkat lunak ini;</li>
        <li>Segala keputusan di dalam penggunaan Aplikasi Seluler ini adalah keputusan secara suka rela atau independen yang dibuat oleh Member/Verified Member ataupun Pengguna tanpa adanya paksaan dari PT KRIPTO INOVASI NUSANTARA dan Pengembang. Atas perihal ini, Member/Verified Member ataupun Pengguna melepaskan PT KRIPTO INOVASI NUSANTARA dan Pengembang dari segala bentuk tuntutan, ganti rugi dan segala tanggung jawab dalam bentuk apa pun;</li>
        <li>Member/Verified Member atau Pengguna menyatakan telah mengerti batasan-batasan keamanan dan privasi namun tidak terbatas kepada: (i) batasan ukuran dan fitur keamanan, privasi, dan autentikasi dalam layanan; dan (ii) segala data dan informasi di dalam layanan mungkin dapat mengarah ke penyadapan, pemalsuan, spam, sabotase, pembajakan kata sandi, gangguan, penipuan, penyalahgunaan elektronik, peretasan, dan kontaminasi sistem, termasuk namun tanpa pembatasan, virus, worms, dan Trojan horses, yang menyebabkan ketidakabsahan, kerusakan, maupun akses yang berbahaya, dan/atau pemulihan informasi atau data dalam komputer Member/Verified Member atau bahaya keamanan dan privasi lainnya. Apabila Member/Verified Member tidak menghendaki untuk dikenai risiko-risiko tersebut, Member/Verified Member disarankan untuk tidak menggunakan Aplikasi Seluler maupun layanan ini.</li>
    </ol>

    <h2>XI. KERJASAMA LAYANAN</h2>
    <ol>
        <li>Demi memudahkan transaksi, dan membangun komunitas Aset Kripto di Indonesia, PT KRIPTO INOVASI NUSANTARA bekerja sama dengan beberapa perusahaan nasional dan internasional yang juga bergerak di dunia Aset Kripto dan blockchain. Partner resmi akan disampaikan melalui media promosi/sosial media PT KRIPTO INOVASI NUSANTARA.</li>
        <li>PT KRIPTO INOVASI NUSANTARA tidak bekerjasama dalam hal apapun dengan perusahaan-perusahaan Arisan Berantai, cloud mining, MLM, Ponzi Scheme, Money Game dan HYIP yang menawarkan profit dari perdagangan atau penggunaan Aset Kripto, dan tidak bekerja sama dengan Biclubnetwork, BTCPanda, MMM Global, MMM Indonesia, Onecoin, Binary, BITCOINTRUST2U, BTCPANDA, BITKINGDOM, BITCLUBNETWORK, MMM GLOBAL, MMM Indonesia, EUROBIT, ILGAMOS, FUTURENET, E-DINAR dan tidak bertanggung jawab atas penggunaan layanan tersebut diatas oleh Member/Verified Member;</li>
        <li>Perusahaan yang tidak terdata didalam Website dapat diartikan sebagai tidak maupun belum bekerja sama dengan PT KRIPTO INOVASI NUSANTARA. PT KRIPTO INOVASI NUSANTARA tidak merekomendasi perusahaan mana pun termasuk perusahaan yang bekerja sama dengan PT KRIPTO INOVASI NUSANTARA. Segala keputusan untuk menggunakan layanan tersebut menjadi tanggung jawab masing-masing Pengguna.</li>
        <li>PT KRIPTO INOVASI NUSANTARA dengan ini menjelaskan bahwa tidak memiliki afiliasi resmi dengan partner yang telah disebutkan pada ayat (3) diatas. Segala transaksi yang terjadi antara Member/Verified Member dengan perusahaan-perusahaan di atas bukan merupakan tanggung jawab PT KRIPTO INOVASI NUSANTARA.</li>
    </ol>

    <h2>XII. PENYELESAIAN PERSELISIHAN PELANGGAN ASET KRIPTO</h2>
    <p>Setiap perselisihan, sengketa atau perbedaan pendapat (selanjutnya disebut sebagai “Perselisihan”) yang timbul sehubungan dengan pelaksanaan kerja sama akan diselesaikan dengan cara sebagai berikut:</p>
    <ol>
        <li>Bahwa setiap Perselisihan, sepanjang memungkinkan, akan diselesaikan dengan cara musyawarah untuk mufakat sebagaimana diatur dalam perjanjian antar para pihak atau peraturan Bappebti.</li>
        <li>Setiap Perselisihan yang tidak dapat diselesaikan secara musyawarah, akan diselesaikan melalui Badan Arbitrase Perdagangan Berjangka Komoditi (BAKTI) atau Pengadilan Negeri Jakarta Utara.</li>
    </ol>

    <h2>XIII. KEADAAN KAHAR/FORCE MAJEURE</h2>
    <p>Yang dimaksud dengan Force Majeure adalah kejadian-kejadian yang terjadi di luar kemampuan dan kekuasaan PT KRIPTO INOVASI NUSANTARA sehingga mempengaruhi pelaksanaan transaksi antara lain namun tidak terbatas pada:</p>
    <ul>
        <li>Gempa bumi, angin topan, banjir, tanah longsor, gunung meletus dan bencana alam lainnya;</li>
        <li>Perang, demonstrasi, huru-hara, terorisme, sabotase, embargo, dan pemogokan massal;</li>
        <li>Kebijakan ekonomi dari Pemerintah yang mempengaruhi secara langsung;</li>
    </ul>
    <p>Sepanjang PT KRIPTO INOVASI NUSANTARA telah melaksanakan segala kewajibannya sesuai dengan peraturan perundang-undangan yang berlaku sehubungan dengan terjadinya Force Majeure tersebut, maka PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Member/Verified Member atau pihak lain mana pun atas segala risiko, tanggung jawab dan tuntutan apa pun yang mungkin timbul sehubungan dengan keterlambatan maupun tidak dapat dilaksanakannya kewajiban akibat Force Majeure.</p>

    <h2>XIV. PENERAPAN PROGRAM ANTI PENCUCIAN UANG DAN PENCEGAHAN PENDANAAN TERORISME (APU/PPT) SERTA POLIFERASI SENJATA PEMUSNAH MASAL</h2>
    <ol>
        <li>Dalam hal terjadinya transaksi mencurigakan yang dilakukan melalui layanan PT KRIPTO INOVASI NUSANTARA, maka PT KRIPTO INOVASI NUSANTARA berhak untuk menghentikan/menonaktifkan Akun PT KRIPTO INOVASI NUSANTARA pada Member/Verified Member dan memblokir dana transaksi serta melakukan penundaan transaksi kepada Member/Verified Member, sampai dengan adanya pemberitahuan dari PT KRIPTO INOVASI NUSANTARA.</li>
        <li>Dalam hal terjadi transaksi mencurigakan dan/atau transaksi yang melebihi batasan volume transaksi yang ditetapkan oleh PT KRIPTO INOVASI NUSANTARA terhadap Member/Verified Member karena alasan apapun juga, maka PT KRIPTO INOVASI NUSANTARA berhak sewaktu-waktu menunda pengkreditan dana ke Akun PT KRIPTO INOVASI NUSANTARA Member/Verified Member dan/atau melakukan pemblokiran Akun Member/Verified Member sampai proses investigasi selesai dilakukan dalam jangka waktu yang ditentukan oleh PT KRIPTO INOVASI NUSANTARA.</li>
        <li>Apabila terbukti bahwa transaksi tersebut pada angka 1 dan 2 tersebut diatas mengenai transaksi mencurigakan adalah transaksi yang melanggar PTT dan/atau peraturan perundang-undangan yang berlaku, maka Member/Verified Member dengan ini Memberi kuasa kepada PT KRIPTO INOVASI NUSANTARA untuk mendebet Aset Kripto pada Dompet Digital PT KRIPTO INOVASI NUSANTARA untuk mengganti kerugian PT KRIPTO INOVASI NUSANTARA yang timbul akibat transaksi tersebut, tanpa mengurangi hak untuk melakukan tuntutan ganti rugi atas seluruh kerugian yang timbul akibat transaksi tersebut dan Member/Verified Member dengan ini setuju bahwa PT KRIPTO INOVASI NUSANTARA tidak wajib melakukan pengembalian atas dana yang ditunda pengkreditannya oleh PT KRIPTO INOVASI NUSANTARA atau dana yang diblokir sebagaimana dimaksud pada angka 2 ketentuan mengenai transaksi mencurigakan ini.</li>
    </ol>
    <p>Pemblokiran dan Pembekuan Akun Member/Verified Member:</p>
    <ol>
        <li>Dalam rangka menjalankan prinsip kehati-hatian, PT KRIPTO INOVASI NUSANTARA berhak dan Member/Verified Member dengan ini Memberi kuasa kepada PT KRIPTO INOVASI NUSANTARA untuk memblokir baik sebagian atau seluruh saldo Member/Verified Member (hold amount) dan/atau mendebetnya serta melakukan pemberhentian Akun, apabila terjadi hal berikut:
            <ul>
                <li>Adanya permintaan dari pihak perbankan dikarenakan adanya kesalahan pengiriman dana dan pihak perbankan tersebut meminta dilakukan pemblokiran;</li>
                <li>Menurut pendapat dan pertimbangan PT KRIPTO INOVASI NUSANTARA terdapat kesalahan penerimaan transaksi;</li>
                <li>PT KRIPTO INOVASI NUSANTARA digunakan sebagai tempat penampungan yang diindikasikan hasil kejahatan termasuk namun tidak terbatas pada Tindak Pidana korupsi, penyuapan, narkotika, psikotropika, penyelundupan tenaga kerja, penyelundupan manusia, bidang perbankan, bidang pasar modal, bidang perasuransian, kepabeanan, cukai, perdagangan manusia, perdagangan senjata gelap, terorisme, penculikan, pencurian, penggelapan, penipuan, pemalsuan, perjudian, prostitusi, bidang perpajakan, dan terorisme;</li>
            </ul>
        </li>
        <li>Member/Verified Member dinilai lalai dalam melakukan kewajiban berdasarkan PTT;</li>
        <li>Kebijakan PT KRIPTO INOVASI NUSANTARA atau oleh suatu ketetapan pemerintah atau instansi yang berwenang atau peraturan yang berlaku, sehingga PT KRIPTO INOVASI NUSANTARA diharuskan melakukan pemblokiran dan atau pembekuan Akun PT KRIPTO INOVASI NUSANTARA tersebut.</li>
        <li>Apabila terjadi pemberhentian Akun, Member/Verified Member setuju dan menyatakan bersedia untuk tetap terikat dengan PTT menghentikan penggunaan layanan PT KRIPTO INOVASI NUSANTARA, Memberikan hak kepada Website/Trading Platform PT KRIPTO INOVASI NUSANTARA untuk menghapus semua informasi dan data dalam server PT KRIPTO INOVASI NUSANTARA, dan menyatakan PT KRIPTO INOVASI NUSANTARA tidak bertanggung jawab kepada Member/Verified Member atau Pihak Ketiga atas penghentian akses dan penghapusan informasi serta data dalam Akun Member/Verified Member.</li>
    </ol>
    <p>Penolakan, Penundaan dan Pembatalan Transaksi Mencurigakan</p>
    <ol>
        <li>PT KRIPTO INOVASI NUSANTARA berhak untuk melakukan penundaan dan/atau penolakan transaksi apabila PT KRIPTO INOVASI NUSANTARA mengetahui atau berdasarkan pertimbangan, menduga bahwa kegiatan penipuan dan/atau aksi kejahatan telah dan/atau akan dilakukan.</li>
        <li>Member/Verified Member setuju dan mengakui bahwa sepanjang diperbolehkan oleh ketentuan Hukum yang berlaku, PT KRIPTO INOVASI NUSANTARA wajib menolak untuk memproses segala transaksi.</li>
        <li>Member/Verified Member mengakui bahwa PT KRIPTO INOVASI NUSANTARA tunduk kepada Undang-Undang tentang kejahatan keuangan, termasuk namun tidak terbatas pada, Undang-Undang Pemberantasan Tindak Pidana Korupsi dan Undang-Undang Tindak Pidana Pencucian Uang yang berlaku di Indonesia dan secara internasional, segala peraturan perundang-undangan yang berlaku di Indonesia dan kebijakan internal PT KRIPTO INOVASI NUSANTARA. Untuk tujuan tersebut, Member/Verified Member dengan ini setuju untuk Memberikan segala informasi yang diminta oleh PT KRIPTO INOVASI NUSANTARA guna memenuhi peraturan perundang-undangan tersebut termasuk namun tidak terbatas pada nama, alamat, usia, jenis kelamin, keterangan identitas pribadi, pendapatan, pekerjaan, harta kekayaan, hutang, sumber kekayaan, tujuan pembukaan Akun, tujuan investasi, segala rencana keuangan atau informasi keuangan terkait lainnya dari Member/Verified Member. Jika diperlukan oleh PT KRIPTO INOVASI NUSANTARA, Member/Verified Member juga setuju untuk menyediakan data terbaru tentang informasi tersebut kepada PT KRIPTO INOVASI NUSANTARA.</li>
        <li>PT KRIPTO INOVASI NUSANTARA berkewajiban untuk mematuhi hukum, peraturan dan permintaan lembaga masyarakat dan pemerintah dalam yurisdiksi yang berbeda-beda yang berkaitan dengan pencegahan atas pembiayaan untuk, antara lain, teroris dan pihak yang terkena sanksi. Hal ini dapat menyebabkan PT KRIPTO INOVASI NUSANTARA untuk melakukan pencegatan dan menyelidiki segala perintah pembayaran dan informasi atau komunikasi lainnya yang dikirimkan kepada atau oleh Member/Verified Member, atau atas nama Member/Verified Member melalui sistem PT KRIPTO INOVASI NUSANTARA. Proses ini juga dapat melibatkan PT KRIPTO INOVASI NUSANTARA untuk melakukan penyelidikan lebih lanjut untuk menentukan apakah nama yang muncul dalam segala transaksi yang dilakukan atau akan dilakukan oleh Member/Verified Member melalui Akunnya adalah nama teroris.</li>
        <li>PT KRIPTO INOVASI NUSANTARA tidak akan bertanggung jawab untuk setiap kerugian (baik secara langsung dan termasuk namun tidak terbatas pada kehilangan keuntungan atau bunga) atau kerugian yang diderita oleh pihak manapun yang timbul dari segala penundaan atau kelalaian dari PT KRIPTO INOVASI NUSANTARA untuk memproses segala perintah pembayaran tersebut atau informasi atau komunikasi lainnya atau untuk melaksanakan segala kewajiban lainnya, yang disebabkan secara keseluruhan atau sebagian oleh segala tindakan yang diambil berdasarkan angka 4 ketentuan Penolakan dan Penundaan Transaksi ini.</li>
        <li>PT KRIPTO INOVASI NUSANTARA berwenang untuk melakukan pemantauan atas Akun Member/Verified Member dalam rangka pencegahan kejahatan keuangan.</li>
        <li>Member/Verified Member mengerti, memahami dan setuju bahwa terhadap setiap transaksi yang telah dilakukan melalui PT KRIPTO INOVASI NUSANTARA bersifat final dan tidak dapat dilakukan pembatalan oleh Member/Verified Member.</li>
    </ol>

    <h2>XIV. PENYAMPAIAN SYARAT DAN KETENTUAN DALAM HAL CALON PEDAGANG FISIK ASET KRIPTO ATAU PEDAGANG FISIK ASET KRIPTO MENGAMBIL POSISI UNTUK DIRI SENDIRI</h2>
    <p>PT Kripto Inovasi Nusantara tidak mengambil posisi untuk diri sendiri dalam perdagangan fisik aset kripto</p>

    <h2>XV. PEMBERITAHUAN</h2>
    <ol>
        <li>Member/Verified Member menyatakan setuju untuk berkomunikasi dengan PT KRIPTO INOVASI NUSANTARA dalam format elektronik, dan menyetujui bahwa semua syarat, kondisi, perjanjian, pemberitahuan, pengungkapan atau segala bentuk komunikasi lainnya yang disediakan oleh PT KRIPTO INOVASI NUSANTARA kepada Member/Verified Member secara elektronik dianggap sebagai tertulis.</li>
        <li>Member/Verified Member menyatakan setuju untuk menerima email dari Website. Email yang dikirim dapat berisi informasi seputar Akun, transaksi, sistem, promosi dan lain sebagainya.</li>
    </ol>

    <h2>XVI. MAINTENANCE DAN DELISTING COIN/TOKEN</h2>
    <ol>
        <li>Maintenance Sistem
            <ol>
                <li>Definisi Maintenance: Maintenance atau pemeliharaan sistem adalah proses rutin yang dilakukan untuk memastikan keamanan, kinerja, dan stabilitas platform perdagangan aset kripto. Proses ini dapat mencakup pembaruan perangkat lunak, peningkatan infrastruktur teknologi, serta pengecekan dan perbaikan sistem yang diperlukan.</li>
                <li>Jadwal Maintenance: PT PT KRIPTO INOVASI NUSANTARA akan melakukan maintenance sistem secara berkala. Jadwal maintenance akan diinformasikan kepada semua pengguna melalui situs web resmi dan media komunikasi lainnya paling tidak 48 jam sebelum maintenance dilakukan.</li>
                <li>Dampak Maintenance: Selama periode maintenance, beberapa atau semua layanan perdagangan mungkin tidak tersedia. Pengguna disarankan untuk melakukan persiapan yang diperlukan, termasuk penyesuaian strategi perdagangan, untuk mengantisipasi potensi ketidaktersediaan layanan.</li>
            </ol>
        </li>
        <li>Delisting Coin/Token
            <ol>
                <li>Kriteria Delisting
                    <p>Coin atau token dapat dikeluarkan dari daftar perdagangan (delisted) berdasarkan kriteria tertentu, termasuk namun tidak terbatas pada, volume perdagangan yang rendah, masalah keamanan, kepatuhan terhadap regulasi yang berlaku, atau kegagalan dalam memenuhi standar operasional yang ditetapkan oleh PT PT KRIPTO INOVASI NUSANTARA.</p>
                </li>
                <li>Prosedur Delisting
                    <ol>
                        <li>Pengumuman: PT PT KRIPTO INOVASI NUSANTARA akan mengumumkan keputusan delisting melalui situs web resmi dan media komunikasi lainnya.</li>
                        <li>Periode Grasi: Setelah pengumuman, akan ada periode grasi yang diberikan kepada pengguna untuk menarik atau menjual aset kripto yang akan dikeluarkan. Periode ini biasanya berlangsung selama 30 hari.</li>
                        <li>Penghapusan: Setelah periode grasi berakhir, coin/token akan secara resmi dikeluarkan dari daftar perdagangan, dan semua perdagangan terkait akan dihentikan.</li>
                    </ol>
                </li>
                <li>Tanggung Jawab Pengguna
                    <p>Pengguna bertanggung jawab untuk secara aktif memonitor pengumuman dari PT PT KRIPTO INOVASI NUSANTARA dan mengambil tindakan yang diperlukan terkait aset kripto yang dimiliki, termasuk penarikan atau penjualan sebelum periode grasi berakhir.</p>
                </li>
            </ol>
        </li>
    </ol>

    <h2>XVII. DOMISILI HUKUM</h2>
    <p>PTT dibuat, ditafsirkan dan diberlakukan berdasarkan hukum negara Republik Indonesia. Website/Trading Platform PT KRIPTO INOVASI NUSANTARA dan Member/Verified Member telah sepakat untuk memilih tempat kediaman hukum yang umum dan tidak berubah pada Kantor Kepaniteraan Pengadilan Negeri Jakarta Utara.</p>

    <h2>XVIII. LAIN-LAIN</h2>
    <ol>
        <li>PTT ini tunduk dan diberlakukan berdasarkan pada UU No 8 Tahun 2010 tentang Tindak Pidana Pencucian Uang dan Peraturan Kepala PPATK PER-09/1.02.2/PPATK/09/12,No. PER-12/1.02/PPATK/06/13, No. PER12/1.02.1/PPATK/09/11 dan No. PER-02/1.02/PPATK/02/2014. Oleh karenanya, segala transaksi dengan nominal diatas Rp 100,000,000,- (seratus juta rupiah) diwajibkan adanya pelaporan transaksi tersebut kepada pihak PPATK.</li>
        <li>Untuk hal-hal yang belum diatur dalam PTT, maka akan berlaku seluruh ketentuan dalam Kitab Undang-Undang Hukum Perdata serta ketentuan-ketentuan peraturan perundang-undangan lainnya yang terkait.</li>
        <li>Jika PT KRIPTO INOVASI NUSANTARA melakukan perubahan terhadap isi PTT ini maka PT KRIPTO INOVASI NUSANTARA akan Memberitahukan perubahannya kepada Member/Verified Member sesuai dengan peraturan perundang-undangan yang berlaku melalui media pemberitahuan yang dianggap baik oleh PT KRIPTO INOVASI NUSANTARA dan selanjutnya Member/Verified Member akan tunduk pada perubahan PTT tersebut. Perubahan setiap lampiran dari PTT akan disepakati dan untuk selanjutnya merupakan satu kesatuan dengan dan merupakan bagian yang tidak terpisahkan dari PTT.</li>
        <li>Apabila Member/Verified Member melakukan tindakan-tindakan di luar ketentuan PTT, maka Member/Verified Member akan bertanggung jawab sepenuhnya dan dengan ini setuju bahwa PT KRIPTO INOVASI NUSANTARA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Member/Verified Member, atau pihak manapun atas segala tuntutan dan atau gugatan dan atau klaim yang diajukan pihak lain sehubungan dengan tindakan-tindakan yang dilakukan Member/Verified Member.</li>
        <li>Member/Verified Member wajib mematuhi seluruh persyaratan yang dicantumkan di dalam PTT. Kelalaian Member/Verified Member didalam mentaati atau melaksanakan isi dari PTT pada satu atau beberapa kali kejadian, tidak akan menghilangkan kewajiban Member/Verified Member untuk tetap memenuhi segala persyaratan yang terdapat di dalam PTT.</li>
        <li>Pertanyaan seputar Ketentuan dan Persyaratan atau perihal lain, dilakukan melalui email support yang tertera dalam Website/Trading Platform.</li>
    </ol>
    <p>*SYARAT DAN KETENTUAN UMUM MEMBER/VERIFIED MEMBER PLATFORM PT KRIPTO INOVASI NUSANTARA INI TELAH DISESUAIKAN DENGAN KETENTUAN PERATURAN PERUNDANG-UNDANGAN YANG BERLAKU</p>

  </div>
</template>

<script>
// node_modules

// applibs

export default {
  components: {},
  data() {
    return {
      web_domain: "coinx.co.id",
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 2em;
  margin-bottom: 2em;
  font-weight: bold;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 1em;
  margin-top: 2em;
  font-weight: bold;
}

h3 {
  font-size: 1em !important;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: bold;
}

table td, th {
    color: #fff;
}
</style>
